import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GlobalStyle } from 'theme/GlobalStyle';
import { ThemeProvider } from 'styled-components';
import { theme } from 'theme/mainTheme';
import HomeView from 'views/HomeView/HomeView';
import MissionView from 'views/MissionView/MissionView';
import CareerView from 'views/CareerView/CareerView';
import JobOffersView from 'views/JobOffersView/JobOffersView';
import RecruitmentView from 'views/RecruitmentView/RecruitmentView';
import PracticeView from 'views/PracticeView/PracticeView';
import PrivacyPolicyView from 'views/PrivacyPolicyView/PrivacyPolicyView';
import CookiesPolicyView from 'views/CookiesPolicyView/CookiesPolicyView';
import JobTemaplte from 'templates/JobTemplate/JobTemplate';
import ScrollToTop from 'assets/Functions/ScrollToTop';
// import axios from 'axios';
// import CookiesAlert from 'components/molecules/CookiesAlert/CookiesAlert';
import { ThemeContext } from 'context';
import AboutUsView from 'views/AboutUsView/AboutUsView';
import ContactView from 'views/ContactView/ContactView';
import TeamView from 'views/TeamView/TeamView';
import CertView from 'views/CertView/CertView';
import ServicesView from 'views/ServicesView/ServicesView';
import ArticleView from 'views/ArticleView/ArticleView';
import ServicesTemplate from 'templates/ServicesTemplate/ServicesTemplate';
import 'i18n';
import { useTranslation } from 'react-i18next';
import PrivacyAlert from 'components/organisms/PrivacyAlert/PrivacyAlert';
import ReactGA from 'react-ga4';
// import WishesModal from 'components/molecules/WishesModal/WishesModal';
// import NotificationBar from 'components/organisms/NotificationBar/NotificationBar';

const Root = () => {
  const isCookies = document.cookie;

  // const [JobsData, setJobsData] = useState([]);
  // const [PracticeData, setPracticeData] = useState([]);
  // const [setHidden, setHiddenFn] = useState(document.cookie.startsWith('cookies=true'));

  // useEffect(() => {
  //   const fetchDataJobs = async () => {
  //     const responseJobs = await axios.get('https://infinisap.com/data/jobs.json');
  //     setJobsData(responseJobs.data);
  //   };
  //   const fetchDataPractice = async () => {
  //     const responsePractice = await axios.get('https://infinisap.com/data/practice.json');
  //     setPracticeData(responsePractice.data);
  //   };
  //   fetchDataJobs();
  //   fetchDataPractice();
  // });

  const [darkMode, darkModefn] = useState(false);
  // const [wishes, setWishes] = useState(true);

  // useEffect(() => {
  //   if (document.cookie.startsWith('cookies=true')) {
  //     setWishes(true);
  //   }
  // }, []);

  const { t } = useTranslation(['newsData', 'servicesData', 'jobOffersData', 'practiceOffersData']);
  const news = t('newsData:news', { returnObjects: true });
  const services = t('servicesData:services', { returnObjects: true });
  const JobsData = t('jobOffersData:jobs', { returnObjects: true });
  const PracticeData = t('practiceOffersData:jobs', { returnObjects: true });

  const setReactGA = () => {
    ReactGA.initialize('G-BX2CQX7ZVW');
    ReactGA.send({
      hitType: 'pageview',
      page: document.location.pathname,
      title: document.title,
    });
  };

  // if (setHidden) {
  //   document.body.style.overflow = 'hidden';
  // } else {
  //   document.body.style.overflow = 'visible';
  // }

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <ThemeContext.Provider value={darkMode}>
          <BrowserRouter basename="/">
            <ScrollToTop />
            {/* <NotificationBar /> */}
            {/* {wishes && <WishesModal setWishes={setWishes} />} */}
            {!isCookies && <PrivacyAlert />}
            {document.cookie.startsWith('cookies=true') && setReactGA()}
            <Routes>
              <Route path="/" exact element={<HomeView darkModefn={darkModefn} />} />
              <Route path="/misja-firmy" exact element={<MissionView darkModefn={darkModefn} />} />
              <Route path="/o-nas" exact element={<AboutUsView darkModefn={darkModefn} />} />
              <Route path="/uslugi" exact element={<ServicesView darkModefn={darkModefn} />} />

              {services.map((data) => (
                <Route
                  key={data.id}
                  path={data.link}
                  exact
                  element={<ServicesTemplate darkModefn={darkModefn} data={data} />}
                />
              ))}

              <Route path="/certyfikaty" exact element={<CertView darkModefn={darkModefn} />} />
              <Route
                path="/kariera"
                exact
                element={<CareerView darkModefn={darkModefn} data={JobsData} />}
              />
              <Route
                path="/kariera/oferty-pracy"
                exact
                element={<JobOffersView darkModefn={darkModefn} data={JobsData} />}
              />
              <Route
                path="/kariera/proces-rekrutacji"
                exact
                element={<RecruitmentView darkModefn={darkModefn} />}
              />
              <Route
                path="/kariera/praktyki"
                exact
                element={<PracticeView darkModefn={darkModefn} data={PracticeData} />}
              />
              <Route path="/kontakt" exact element={<ContactView darkModefn={darkModefn} />} />
              <Route path="/zespol" exact element={<TeamView darkModefn={darkModefn} />} />

              {news.map((item) => (
                <Route
                  path={item.path}
                  exact
                  element={<ArticleView darkModefn={darkModefn} data={item} />}
                />
              ))}

              {JobsData.map((item) => (
                <Route
                  key={item.path}
                  path={item.path}
                  element={<JobTemaplte jobId={item.id} key={item.path} data={JobsData} />}
                />
              ))}

              {PracticeData.map((item) => (
                <Route
                  key={item.path}
                  path={item.path}
                  element={<JobTemaplte jobId={item.id} key={item.path} data={PracticeData} />}
                />
              ))}

              <Route path="polityka-prywatnosci" element={<PrivacyPolicyView />} />
              <Route path="polityka-cookies" element={<CookiesPolicyView />} />
            </Routes>
          </BrowserRouter>
        </ThemeContext.Provider>
      </ThemeProvider>
    </>
  );
};

export default Root;
