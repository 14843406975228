import Card from 'components/molecules/Card/Card';
import styled from 'styled-components';
// import { NewsData } from 'data/NewsData';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.section`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-flow: column;
    gap: 20px;

    max-width: 1920px;
    margin: 0 auto 56px;
    padding: 36px 0 0px;

    width: calc(100% - 160px);
    max-width: 1920px;

    @media (max-width: 1024px) {
        padding: 24px 20px 8px;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
    }
`;

const StyledCard = styled(Card)`
    grid-column: span 4;
    @media (max-width: 1024px) {
        grid-column: 1 / span 2;
    }
`;

const NewsTemplate = () => {
  const { t } = useTranslation(['newsData']);
  const section = t('news', { returnObjects: true });
  return (
    <Wrapper>
        {section.map(
          (item) => (
            <StyledCard key={item.id} {...item} />
          )
        )}
    </Wrapper>
  );
};

export default NewsTemplate;