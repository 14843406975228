import styled, { css, keyframes } from 'styled-components';
import NButtonPrimary from 'components/atoms/Buttons/NButtonPrimary';
import NButtonSecondary from 'components/atoms/Buttons/NButtonSecondary';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import NButtonTertiary from 'components/atoms/Buttons/NButtonTertiary';
import { useTranslation } from 'react-i18next';
import PrivacySettings from './PrivacySettings';

const FadeIn1 = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, 60px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 44px;
  max-width: 760px;

  position: fixed;
  bottom: 30px;
  z-index: 3;
  padding: 24px 36px 40px;

  color: ${({ theme }) => theme.white};
  border-radius: 4px;
  transition: all 0.32s ease-in-out;
  box-shadow: 0 14px 34px -6px #13164d9e;
  background: linear-gradient(183deg,hsl(233.33deg 30.2% 93.86%) 5%,#fff 90%);
  left: 50%;
  bottom: 5%;

  transform: translate(-50%, 0);
  animation: .41s ${FadeIn1} ease-in-out;
  animation-delay: .7s;
  animation-fill-mode: both;

  @media (max-width: 1024px) {
    padding: 12px;
    padding-bottom: 20px;
    width: 90%;
    row-gap: 0;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 12px;
    flex-direction: column;
  }
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-top: 12px;
    @media (max-width: 1024px) {
        margin-top: 4px;
        margin-bottom: 0px;
    }
`;

const Heading = styled.h2`
    letter-spacing: -0.38px;

    font-size: 2.2rem;
    line-height: 118%;
    font-weight: 500;
    color: #070B11;
`;

const Buttons = styled.div`
    margin: 0;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 44px;
    @media (max-width: 1024px) {
      width: 100%;
      padding-top: 20px;
      flex-direction: column;
      row-gap: 20px;
      text-align: center;
    }
`;

const FadeIn2 = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const TintBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(0, 12, 51, .62);
  animation: .21s ${FadeIn2} ease-in-out;

  ${({ dark }) => dark && css`
    background-color: rgba(32, 36, 53, 0.5);
  `}
`;

const Paragraph = styled.p`
    column-gap: 20px;
    color: rgba(12, 20, 29, 0.8);

    font-size: 1.5rem;
    font-weight: 400;
    line-height: 154%;

    white-space: pre-line;
    @media (max-width: 1024px) {
      font-size: 1.24rem;
      line-height: 140%;
    }

`;

const Link = styled(NavLink)`
  color: #002afa;
  text-decoration: none;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 44px;
`;

const CloseSettings = styled.span`
  font-weight: 700;
`;

const StyledNButtonSecondary = styled(NButtonSecondary)`
  opacity: 0.7;
  /* align-self: flex-start; */
  margin-bottom: 12px;
`;

const PrivacyAlert = () => {
  const modifySingleChars = (str) => str.replace(/ \b([a-zA-Z]{1,3})\b /g, ' $1'.concat('\u00A0'));
  const handleSetCookies = (name, value, days) => {
    const date = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * days);
    document.cookie = `${name}=${value}; expires = ${date.toGMTString()}`;
    document.reload();
  };
  const [isPrivacySettings, isPrivacySettingsFn] = useState(false);
  const [checked, setChecked] = useState(true);

  const { t } = useTranslation(['privacyAlert']);

  return (
    <>
    <Wrapper>
        <InnerWrapper>
          <Header>
              <Heading>{t('header.heading')}</Heading>
          </Header>
          <Paragraph>{modifySingleChars(t('header.text'))}
            <Link to="/polityka-prywatnosci">{t('header.linkName')}</Link>
          </Paragraph>
        </InnerWrapper>
          {isPrivacySettings && (
            <PrivacySettings
              checked={checked}
              setChecked={setChecked}
            />
          )}
          <Buttons>
              <StyledNButtonSecondary secondary="secondary" onClick={() => isPrivacySettingsFn(!isPrivacySettings)}>
              {isPrivacySettings && <CloseSettings>{t('button.close')}</CloseSettings> } {t('button.open')}
              </StyledNButtonSecondary>
              <ButtonsWrapper>
                <NButtonSecondary secondary="secondary" onClick={() => handleSetCookies('cookies', false)}>{t('button.cancel')}</NButtonSecondary>
                {
                  checked ? (<NButtonPrimary secondary="secondary" onClick={() => handleSetCookies('cookies', true, 30)}>{t('button.accept')}</NButtonPrimary>)
                    : (<NButtonTertiary secondary="secondary" onClick={() => handleSetCookies('cookies', false)}>{t('button.save')}</NButtonTertiary>)
                }
              </ButtonsWrapper>
          </Buttons>
    </Wrapper>
    <TintBackground />
    </>
  );
};

export default PrivacyAlert;