import React from 'react';
import styled from 'styled-components';
import Image01 from 'assets/Services/konsultingsap.jpg';
import PropTypes from 'prop-types';
import NButtonSecondary from 'components/atoms/Buttons/NButtonSecondary';
import { useMediaQuery } from 'react-responsive';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  gap: 20px;
  min-height: 100px;
  width: calc(100% - 160px);
  margin: 0px auto 72px;
  max-width: 1920px;
  border-radius: 4px;
  margin-bottom: 121px;

  @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      padding: 24px 20px 8px;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
    }
`;

const ImageWrapper = styled.div`
  grid-column: 1 / -1;
  overflow: hidden;
  aspect-ratio: 4 / 1;
  border-radius: 4px;
  grid-row: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: transparent;

  @media (max-width: 1024px) {
    order: 0;
    aspect-ratio: 2 / 1;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const ContainerWrapper = styled.div`
  grid-column: 1 / -1;
  display: flex;
  grid-row: 2;
  flex-direction: column;
  row-gap: 96px;
  border-radius: 4px;
  padding: 56px 76px 144px 56px;
  background: linear-gradient(180deg, #fff 0%, rgb(246 247 255));
  border-left: unset;
  border-bottom: unset;
  border-right: unset;
  position: relative;
  transform: translateY(-96px);
  width: 80%;
  margin: 0  auto;

  @media (max-width: 1024px) {
    transform: unset;
    row-gap: 72px;
    padding: 36px 20px 72px 20px;
    order: 1;
    width: 100%;
  }
`;

const ContainerInnerWrapper = styled.div`
  display: flex;
  row-gap: 56px;
  flex-direction: column;

  @media (max-width: 1024px) {
    row-gap: 36px;
  }
`;

const Heading = styled.h1`
  font-size: 4.8rem;
  line-height: 120%;
  font-weight: 300;
  letter-spacing: -1.04px;
  color: #0B121B;

  @media (max-width: 1024px) {
      font-size: 3.2rem;
      letter-spacing: -1.08px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;

const Paragraph = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 158%;
  color: #0B121B;
  column-gap: 40px;
  max-width: 90%;
  white-space: pre-line;
  @media (max-width: 1024px) {
    max-width: unset;
  }
`;

const Buttons = styled.div`
  display: flex;
  column-gap: 44px;
  align-items: center;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 36px;
    margin-top: 0px;
  }
`;

const List = styled.ul`
  line-height: 168%;
  margin: 20px 0 36px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  `;

const ListItem = styled.li`
  border-left: 1px solid hsl(230,82%,45%);
  padding-left: 18px;
`;

const ServicesTemplateContentC = ({ data }) => {
  const modifySingleChars = (str) => str.replace(/ (\b[a-zA-Z]{1,3}) /g, ' $1'.concat('\u00A0'));
  const isQueryMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
  <Wrapper>
    <ImageWrapper>
      <Image src={!isQueryMobile ? data.image : data.imageM} alt={data.title} />
    </ImageWrapper>
    <ContainerWrapper>
      <ContainerInnerWrapper>
        <Heading>{data.title}</Heading>
        <ContentWrapper>
        <Paragraph>
        {
          data.content?.map((el) => (
            <>
              {modifySingleChars(el.item)}
              {el.subitem
                && (
                <List>
                    {el.subitem?.map((subitem) => (<ListItem>{modifySingleChars(subitem)}</ListItem>))}
                </List>
                )}
            </>
          ))
        }
        </Paragraph>
        </ContentWrapper>
      </ContainerInnerWrapper>
      <Buttons>
        <NButtonSecondary colorParent="#0B121B" to="/kontakt">{data.ctaprimary}</NButtonSecondary>
      </Buttons>
    </ContainerWrapper>
  </Wrapper>
  );
};

Image.propTypes = { src: PropTypes.string };
Image.defaultProps = { src: Image01 };

export default ServicesTemplateContentC;