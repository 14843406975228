import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    *, *::before, *::after{
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    h1, h2, h3, h4, h5, h6, p{
        margin: 0;
        padding: 0;
    }

    html{
        font-size: 62.5%;
    }

    body{
        font-size: 1.6rem;
        font-family: 'Inter', Arial, sans-serif;
        margin: 0;
        padding: 0;
        background: linear-gradient(150deg,hsl(235.91deg 99.8% 92.88% / 41%) 24%,hsl(233.33deg 30.2% 93.86%) 36%);
    }
`;

export const GlobalStyleDark = createGlobalStyle`
    body{
        background: linear-gradient(153deg,rgba(29,33,50,0.20) 0%,rgb(70 52 52 / 20%) 100%), #1D2132
    }
`;