import styled from 'styled-components';
import Heading from 'components/atoms/Typography/Headings';
import Text from 'components/atoms/Typography/Text';
import { NavLink } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1024px) {
      padding: 24px 30px 40px;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  row-gap: 28px;
  width: 100%;
  @media (max-width: 1024px) {
    row-gap: 20px;
    max-width: 360px;
  }
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.blue900};
  /* text-align: justify; */
  span{
    /* font-style: italic; */
    font-weight: 500;
    color: ${({ theme }) => theme.blue900};
  }
`;

const LinkMail = styled(NavLink)`
  font-weight: 600;
  font-style: normal;
  color: ${({ theme }) => theme.accent};
  text-decoration: none;
`;

const Header = styled.header`
    padding-bottom: 36px;
    border-bottom: 2px solid #1554CF;
    display: flex;
    flex-direction: column;
    row-gap: 28px;
    width: 100%;
    @media (max-width: 1024px) {
        padding-bottom: 22px;
    }
`;

const StyledHeading = styled(Heading)`
    color: ${({ theme }) => theme.greyAccent};
    strong{
        font-weight: 600;
        color: ${({ theme }) => theme.blue900};
    }
`;

const JobFormAppNotReadyYet = ({ title }) => {
  // const { t } = useTranslation('practiceOffersData');
  // const BreadcrumbsData = t('practiceOffersData:breadcrumbs', { returnObjects: true });

  const body = `Szanowni Państwo,%0D%0A%0D%0AW odpowiedzi na Państwa ofertę pracy zamieszczoną na stronie www.infinisap.com pragnę%0D%0Aprzedstawić swoją kandydaturę na stanowisko ${title}. Wszelkie informacje%0D%0Ana swój temat zamieszczam w dołączonym CV oraz liście motywacyjnym.%0D%0A%0D%0AWyrażam zgodę na przetwarzanie moich danych osobowych dla potrzeb niezbędnych do realizacji rekrutacji%0D%0Aorganizowanej przez Infinisap Sp. z o.o. na stanowisko Junior SAP® Basis Administrator. %0D%0A%0D%0AWyrażam zgodę na przetwarzanie moich danych osobowych w przyszłych rekrutacjach organizowanych przez Infinisap Sp. z o.o.%0D%0A%0D%0AZ poważaniem,`;
  const link = `mailto:office@infinisap.com?subject=${title}&body=${body}`;
  return (
    <Wrapper>
        <InnerWrapper>
        <Header>
            <StyledHeading tag="h4"><strong>{title}</strong></StyledHeading>
            <StyledText>W celu wzięcia udziału w rekturtacji zapraszamy do przesłania dokumentów aplikacyjnych – CV oraz listu motywacyjnego - na nasz adres e-mail:&nbsp;
              <LinkMail to={link}>office@infinisap.com</LinkMail>.
            </StyledText>
        </Header>
            <StyledText small>Prosimy o zawarcie w tytule i treści e-maila informacji na temat stanowiska, na które Państwo aplikują oraz klauzuli: <span>„Wyrażam zgodę na przetwarzanie moich danych osobowych dla potrzeb niezbędnych do realizacji rekrutacji organizowanej przez Infinisap Sp. z o.o. na stanowisko <strong>{title}</strong>”.</span></StyledText>
            <StyledText small>Przesłane dokumenty aplikacyjne mogą być wykorzystane w przyszłych rekrutacjach. Jeśli wyrażasz na to zgodę, umieść w treści maila informację: <span>„Wyrażam zgodę na przetwarzanie moich danych osobowych w przyszłych rekrutacjach organizowanych przez Infinisap Sp. z o.o”.</span></StyledText>
            <StyledText small><strong>Dziękujemy za udział w rekrutacji!</strong></StyledText>
        </InnerWrapper>
    </Wrapper>
  );
};

export default JobFormAppNotReadyYet;