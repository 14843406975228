import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

const Wrapper = styled.div`
    margin: 36px auto;
`;

const InnerWrapper = styled.div`
    background-color: #595B64;
    gap: 20px;
    width: 100vw;
    aspect-ratio: 4 / 1;
    border-radius: 4px;

    width: calc(100% - 160px);
    margin: 0 auto;
    max-width: 1920px;

    overflow: hidden;

    @media (max-width: 1024px) {
        aspect-ratio: 2 / 1;
        width: calc(100% - 40px);
    }
`;

const Image = styled.img`
    width: 100%;
`;

const HeroArticle = ({ imageXL, imageXLM }) => {
  const isQueryMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  return (
    <Wrapper>
        <InnerWrapper>
            {isQueryMobile ? (
                <Image src={imageXLM} />
            ) : (
                <Image src={imageXL} />
            )}
        </InnerWrapper>
    </Wrapper>

  );
};

export default HeroArticle;