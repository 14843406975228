import styled, { css } from 'styled-components';
import { CertData } from 'data/CertData';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import CertItem from './CertItem';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: repeat(2, 1fr);
  column-gap: 72px;

  padding: 44px 36px;
  border-radius: 4px;
  background: #fff;

  width: calc(100% - 160px);
  margin: 24px auto 172px;
  max-width: 1920px;

  transition: all 0.32s ease-in-out;
  box-shadow: 0px 10px 24px -7px rgba(221, 221, 221, 0.6);

  border: 1px solid transparent;
  box-shadow: 0 14px 24px -7px #0008d912;

  &:hover {
    cursor: pointer;
    transform: translateY(-4px);
    box-shadow: 0 14px 24px -7px hsla(238, 100%, 43%, 0.15);
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    aspect-ratio: unset;
    width: calc(100% - 40px);
    margin: 20px;

    padding: 0;
    box-shadow: unset;
    background: transparent;
    row-gap: 28px;
    margin-bottom: 96px;

    &:hover {
      transform: unset;
      box-shadow: unset;
      border: unset;
    }
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const Caption = styled.span`
  font-size: 1.3rem;
  font-weight: 500;
  color: rgba(12, 20, 29, 0.7);
  letter-spacing: 0.04em;
  text-transform: uppercase;
`;

const Heading = styled.h2`
  letter-spacing: -0.38px;

  font-size: 2.2rem;
  line-height: 118%;
  font-weight: 500;
  color: #070b11;
`;

const ButtonMore = styled.a`
  grid-column: 1;
  grid-row: 2;
  display: flex;
  align-items: end;
  column-gap: 10px;
  color: #1963d1;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 143%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  row-gap: 3px;
  justify-self: flex-start;
  align-self: flex-end;

  @media (max-width: 1024px) {
    order: 1;
    align-self: flex-start;
  }

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #1963d1;
    transition: transform 0.28s ease-in-out;
    transform: translateX(-100%);
  }

  ${({ isHover }) =>
    isHover &&
    css`
      cursor: pointer;
      &::after {
        transform: translateX(0);
        width: 100%;
      }
    `}
`;

const CertWrapper = styled.div`
  grid-column: 2;
  grid-row: 2 span;

  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 32px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
`;

const Cert = ({ showAll }) => {
  const [isHover, isHoverFn] = useState(false);
  const [isCertFull, isCertFullFn] = useState(showAll);
  const isQueryMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const { t } = useTranslation(['certification']);

  return (
    <Wrapper
      onMouseEnter={() => isHoverFn(true)}
      onMouseLeave={() => isHoverFn(false)}
      onClick={() => isCertFullFn(!isCertFull)}
    >
      <HeadingWrapper>
        <Caption>{t('cert.caption')}</Caption>
        <Heading>{t('cert.heading')}</Heading>
      </HeadingWrapper>
      {!isQueryMobile && (
        <ButtonMore isHover={isHover}>{isCertFull ? t('cert.btn2') : t('cert.btn1')}</ButtonMore>
      )}
      <CertWrapper>
        {!isQueryMobile
          ? CertData.slice(0, isCertFull ? CertData.length : 7).map((item) => (
              <CertItem
                src={item.image}
                alt={item.alt}
                border={item.border}
                noShadow={item.noShadow}
              />
            ))
          : CertData.slice(0, isCertFull ? CertData.length : CertData.length).map((item) => (
              <CertItem
                src={item.image}
                alt={item.alt}
                border={item.border}
                noShadow={item.noShadow}
              />
            ))}
      </CertWrapper>
    </Wrapper>
  );
};

export default Cert;
