import styled from 'styled-components';
import arrow from 'assets/Icons/arrow.svg';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const ImageWrapper = styled.div`
    overflow: hidden;
    aspect-ratio: 16 / 9;
    border-radius: 4px;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    transform-origin: center;
    transition: transform 0.4s cubic-bezier(0.7, 0, 0.29, 1);
`;

const ArrowRight = styled.span`
    background-image: url(${arrow});
    width: 15px;
    height: 14px;
    margin-top: 6px;
    margin-left: 8px;
    transition: transform 0.4s cubic-bezier(0.7, 0, 0.29, 1);
    background-repeat: no-repeat;
    background-position: center;
`;

const Wrapper = styled(NavLink)`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 8px;
    padding-bottom: 36px;
    background-color: #fff;
    border-radius: 4px;
    transition: all 0.32s ease-in-out;
    box-shadow: 0px 10px 24px -7px rgba(221, 221, 221, .6);
    box-shadow: 0 14px 24px -7px #0008d912;
    background: linear-gradient(183deg,#d7dee6a3 5%,#fff 90%);
    text-decoration: none;

    &:hover{
        box-shadow: 0 14px 24px -7px hsla(238, 100%, 43%, 0.15);
        transform: translateY(-20px);
        ${Image}{
            transform: scale(1.1);
        }
        ${ArrowRight}{
            transform: translateX(8px);
        }
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding: 0 8px;
    margin-top: 12px;
    @media (max-width: 1024px) {
        margin-top: 4px;
        margin-bottom: 0px;
    }
`;

const Caption = styled.span`
    font-size: 1.2rem;
    font-weight: 500;
    color: rgba(12, 20, 29, 0.7);
    letter-spacing: 0.07em;
    text-transform: uppercase;
`;

const Heading = styled.h2`
    letter-spacing: -0.38px;

    font-size: 2.2rem;
    line-height: 118%;
    font-weight: 500;
    color: #070B11;
`;

const Paragraph = styled.p`
    column-gap: 20px;
    color: rgba(12, 20, 29, 0.8);
    padding: 0 8px;

    font-size: 1.5rem;
    font-weight: 400;
    line-height: 154%;
`;

const sliceDescription = (description) => {
  if (description.length < 45) { return description; }
  const descriptionArr = (description.trim().substring(0, 181)).split(' ');
  if ((descriptionArr[descriptionArr.length - 1]).length <= 4) {
    descriptionArr.pop();
  }
  return `${descriptionArr.join(' ')}...`;
};

const Card = ({
  className, category, image, title, text, path
}) => (
<Wrapper className={className} to={path}>
    <ImageWrapper>
        <Image src={image} />
    </ImageWrapper>
    <HeaderWrapper>
        <Caption>{category}</Caption>
        <Heading>{title}</Heading>
    </HeaderWrapper>
    <Paragraph>{sliceDescription(text)}</Paragraph>
    <ArrowRight />
</Wrapper>
);

Card.propTypes = {
  category: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

Card.defaultProps = { category: 'Infinisap', };

export default Card;