import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PrivacyCheckbox from './PrivacyCheckbox';

const Wrapper = styled.div``;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  margin: 0;
  padding: 0;


  @media (max-width: 1024px) {
    width: 100%;
    padding: 12px;
    flex-direction: column;
  }
`;

const ListItem = styled.li`
  list-style: none;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding-bottom: 10px;
  padding-top: 6px;
  border-bottom: 1px solid rgba(12, 20, 29, 0.4);

  &:last-child{
    border-bottom: unset;
  }
`;

const Caption = styled.span`
    font-size: 1.4rem;
    font-weight: 600;
    color: rgba(12, 20, 29, 0.9);
    letter-spacing: 0.07em;
    text-transform: uppercase;

    @media (max-width: 1024px) {
      font-size: 1.24rem;
    }
`;

const PrivacySettings = ({ checked, setChecked }) => {
  const { t } = useTranslation(['privacyAlert']);
  const settings = t('privacyAlert:settings', { returnObjects: true });

  return (
    <Wrapper>
        <List>
          {settings.map((item) => (
            item.disabled ? (
              <ListItem>
              <Caption>{item.name}</Caption>
              <PrivacyCheckbox
                checked
                disabled
                label={item.text}
              />
              </ListItem>
            ) : (
              <ListItem>
            <Caption>{item.name}</Caption>
            <PrivacyCheckbox
              defaultChecked={checked}
              onChange={
                () => {
                  setChecked(!checked);
                }
              }
              label={item.text}
            />
              </ListItem>
            )
          ))}

        </List>
    </Wrapper>
  );
};

export default PrivacySettings;