import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.label`
    display: flex;
    column-gap: 8px;
    align-self: flex-start;
    align-items: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const Paragraph = styled.p`
    column-gap: 20px;
    color: rgba(12, 20, 29, 0.9);
    font-size: 1.24rem;
    font-weight: 400;
    line-height: 148%;

    white-space: pre-line;

    @media (max-width: 1024px) {
      line-height: 140%;
    }


`;

const Checkmark = styled.span`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    border-radius: 7px;
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.blue900};

  &::after{
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 1.9px 1.9px 0;
    transform: translate(0px, -1.2px) rotate(45deg);
    content: "";
    display: none;
  }
`;

const Input = styled.input`
  display: none;

  &:checked ~ ${Checkmark}{
    background-color: #002afa;
    border: #002afa;
  }

  &:disabled ~ ${Checkmark}{
    background-color: #707070;
    border: 1px solid #707070;
  }

  &:checked ~ ${Checkmark}::after {
    display: block;
  }

`;

const PrivacyCheckbox = ({
  label, required, ...props
}) => (
    <Wrapper>
        <Input type="checkbox" {...props} />
        <Checkmark />
        <Paragraph small="true">{label}</Paragraph>
    </Wrapper>
);

export default PrivacyCheckbox;