import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Head from 'Head/Head';
import Footer from 'components/organisms/Footer/Footer';
import Jobs from 'components/organisms/Jobs/Jobs';
import NNavigation from 'components/organisms/Navigation/NNavigation';
import Breadcrumbs from 'components/organisms/Breadcrumbs/Breadcrumbs';
import PracticeHeading from 'components/organisms/Practice/PracticeHeading';
// import JobOffersHero from 'components/organisms/JobOffers/JobOffersHero';
import { useTranslation } from 'react-i18next';

const helmetContext = {};

const JobsOffersView = ({ darkModefn }) => {
  const { t } = useTranslation('jobOffersData');
  const BreadcrumbsData = t('jobOffersData:breadcrumbs', { returnObjects: true });
  // const HeroData = t('jobOffersData:hero', { returnObjects: true });
  const JobsData = t('jobOffersData:jobs', { returnObjects: true });
  const TitleData = t('jobOffersData:title', { returnObjects: true });

  useEffect(() => {
    darkModefn(false);
  }, [darkModefn]);
  return (
    <>
      <HelmetProvider context={helmetContext}>
        <Head
          title="Oferty pracy na stanowiska SAP® Basis Administrator, Junior SAP® Basis Administrator, SAP® Basis Konsultant."
          description="Wybierz karierę z Infinisap 2023. Najważniejsi są nasi ludzie. Nie ma nic waniejszego od Ciebie."
        />
      </HelmetProvider>
      <NNavigation />/
      <Breadcrumbs data={BreadcrumbsData} />
      {/* <JobOffersHero data={HeroData} /> */}
      <PracticeHeading data={TitleData} />
      <Jobs data={JobsData} />
      <Footer />
    </>
  );
};

export default JobsOffersView;
