import React from 'react';
import styled from 'styled-components';
import arrowNavItem from 'assets/Icons/arrowNavItem.svg';
import { NavLink } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100vw;
  padding: 10px 12px;
  transform: translateY(-1px);

  width: calc(100% - 160px);
  margin: 20px auto;
  max-width: 1920px;
  border-bottom: 1px solid #B1B1B1;

  @media (max-width: 1024px) {
    padding: 10px 12px;
    padding: 10px 0px;
    margin: 0 auto;
    width: calc(100% - 40px);
    /* display: none; */
  }
`;

const Items = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
  @media (max-width: 1024px) {
    column-gap: 8px;
  }
`;

const Item = styled(NavLink)`
  display: flex;
  column-gap: 12px;
  text-decoration: none;
  color: #0B121B;
  &::after{
    content: '';
    mask-image: url(${arrowNavItem});
    mask-repeat: no-repeat;
    background: #0C141D;
    opacity: .7;
    grid-column: 2;
    grid-row: 1;
    align-self: center;
    width: 11px;
    height: 7px;
    transform: rotate(-90deg);
  }
  &:last-child{
    font-weight: 600;
    ::after{
     display: none;
    }
  }

  @media (max-width: 1024px) {
    font-size: 1.2rem;
    column-gap: 8px;
    /* &:nth-child(1){
      display: none;
    } */
    &::after{
      width: 9px;
      height: 5px;
    }
    /* display: none; */
  }
`;

const Breadcrumbs = ({ data }) => (
  <Wrapper>
    <Items>
      {data?.map((item) => (
        <Item to={item.link}>{item.name}</Item>
      ))}
    </Items>
  </Wrapper>
);

export default Breadcrumbs;