import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import ArrowIcon from 'assets/Icons/arrowRightWhite.svg';
import { ThemeContext } from 'context';

const Label = styled.span`
  font-size: 1.4rem;
  color: #3F4457;
  flex-grow: 1;
  line-height: 154%;
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  text-align: left;
  white-space: pre-line;

  @media (max-width: 1024px) {
    text-align: center;
    font-size: 1.25rem;
    white-space: nowrap;
    br{
      display: none;
    }
  }

  ${({ dark }) => dark && css`
    color: rgba(242, 250, 252, 0.94);
  `}
`;

const Icon = styled.span`
  mask-image: url(${ArrowIcon});
  mask-repeat: no-repeat;
  background-color: #3F4457;
  width: 13px;
  height: 10px;
  transform: rotate(180deg);
`;

const Wrapper = styled(NavLink)`
  display: flex;
  flex-shrink: 0;
  border-radius: 3px;
  text-decoration: none;
  align-items: center;
  justify-content: space-between;
  transition: all 0.28s cubic-bezier(0.7, 0, 0.29, 1);

  border: 1px solid transparent;
  text-align: center;
  padding: 14px 28px;
  border-radius: 8px;
  column-gap: 24px;
  background-color: ${({ theme }) => theme.accent};
  background-color: transparent;
  border: 1px solid #3F4457;

  @media (max-width: 1024px) {
    padding: 16px;
    max-width: unset;
  }

  &::after{
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
  }

  &:hover{
    background-color: #3F4457;
    ${Label}{
      color: #fff;
    }
    ${Icon}{
      background: #fff;
    }
  }

  ${({ dark }) => dark && css`
    background-color: transparent;
    border: 1px solid rgba(242, 250, 252, 0.68);

    &:hover{
      background-color: transparent;
      border: 1px solid rgba(242, 250, 252, 0.98);
    }
  `}
`;

const NButtonBack = ({
  children, icon, ...props
}) => {
  const isDarkModeContext = useContext(ThemeContext);

  return (
  <Wrapper dark={isDarkModeContext} {...props}>
    {icon && <Icon dark={isDarkModeContext} />}
    <Label dark={isDarkModeContext}>{ children }</Label>
  </Wrapper>
  );
};

export default NButtonBack;