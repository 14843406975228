export const theme = {
  accent: 'hsl(230, 82%, 45%)',
  greyAccent: 'hsl(226, 20%, 52%)',
  yellowAccent: 'hsl(40, 72%, 66%)',
  white: 'hsl(0, 0%, 100%)',
  grey900: '#25262A',
  blue900: '#011452',
  grey100: 'hsl(0, 0%, 95%)',

  a_blue900: '#1B1E2E',

  fontSize: {
    xs: '1.0rem',
    s: '1.2rem',
    m: '1.5rem'
  },

  fontWeight: {
    s: '400',
    m: '600',
    l: '700'
  },

};