import React, { useState, useContext } from 'react';
import NLogo from 'components/atoms/Logo/NLogo';
import styled, { css, keyframes } from 'styled-components';
import NHamburger from 'components/atoms/Buttons/Hamburger/NHamburger';
import { ThemeContext } from 'context';
import { useMediaQuery } from 'react-responsive';
// import { NvigationData } from 'data/NavigationData';
import { useTranslation } from 'react-i18next';
import NavItem from './NavItem';

const StyledNLogo = styled(NLogo)`
  align-self: flex-end;
  padding-bottom: 20px;
  @media (max-width: 1024px) {
    padding-bottom: 0;
  }
`;

const FadeIn2 = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const InnerWrapper = styled.div`
  display: flex;
  padding: 20px 0 0 0;
  justify-content: space-between;
  position: relative;
  z-index: 2;

  width: calc(100% - 160px);
  margin: 0px auto;
  max-width: 1920px;

  @media (max-width: 1024px) {
    padding: 20px;
    align-items: center;
    position: relative;
    width: 100%;
  }
`;

const TintBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(0, 12, 51, 0.62);
  animation: 0.21s ${FadeIn2} ease-in-out;

  ${({ dark }) =>
    dark &&
    css`
      background-color: rgba(32, 36, 53, 0.5);
    `}
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: flex-end;
  align-items: flex-end;
  row-gap: 36px;

  @media (max-width: 1024px) {
    display: grid;
    position: absolute;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 88vw;
    padding: 96px 30px 144px 36px;
    background-color: #e8e9f3;
    box-shadow: -4px 4px 10px 0px rgba(27, 34, 53, 0.1);
    transform: translateX(100%);
    transition: transform 0.28s cubic-bezier(0.7, 0, 0.29, 1);
    justify-content: stretch;
    overflow-y: scroll;

    ${({ isMobileNavOpen }) =>
      isMobileNavOpen &&
      css`
        transform: translateX(0);
      `}
  }
`;

const Nav = styled.nav`
  display: flex;
  column-gap: 36px;
  order: 1;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    align-items: stretch;
    order: 0;
    row-gap: 20px;
  }

  color: #0c141d;
  --borderColor: #002afa;
  ${({ dark }) =>
    dark &&
    css`
      color: rgba(242, 250, 252, 0.94);
      --borderColor: #f2fafc;
    `}

  @media (max-width: 1024px) {
    color: #0c141d;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;

  ${({ isTint }) =>
    isTint &&
    css`
      background-color: #e8e9f3;
      ${Nav} {
        ${({ dark }) =>
          dark &&
          css`
            color: #0c141d;
            --borderColor: #002afa;
          `}
      }
    `}
`;

const Lang = styled.div`
  display: flex;
  column-gap: 12px;
  order: 0;

  @media (max-width: 1024px) {
    column-gap: 20px;
    ${({ dark }) =>
      dark &&
      css`
        color: #0c141d;
      `}
  }

  ${({ dark }) =>
    dark &&
    css`
      color: rgba(242, 250, 252, 0.94);
    `}

  ${({ tint }) =>
    tint &&
    css`
      ${({ dark }) =>
        dark &&
        css`
          color: #0c141d;
        `}
    `}
`;

const LangItem = styled.a`
  color: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.12px;
  opacity: 0.4;
  text-decoration: none;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
    `}

  &:hover, &.active {
    opacity: 1;
    cursor: pointer;
    &::after {
      transform: translateX(0);
      width: 100%;
    }
  }
`;

const NNavigation = () => {
  const [isTint, isTintFn] = useState(false);
  const [isMobileNavOpen, isMobileNavOpenFn] = useState(false);
  const isDarkModeContext = useContext(ThemeContext);
  const isQueryMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const { i18n } = useTranslation();

  const { t } = useTranslation(['navigationData']);
  const navigationData = t('navigation', { returnObjects: true });

  const lngs = {
    pl: { nativeName: 'Pl' },
    de: { nativeName: 'De' },
    en: { nativeName: 'En' },
  };

  const handleClick = (e) => {
    e.preventDefault();
    isMobileNavOpenFn(false);
  };

  return (
    <>
      <Wrapper isTint={isTint} isMobileNavOpen={isMobileNavOpen} dark={isDarkModeContext}>
        <InnerWrapper>
          {isTint ? <StyledNLogo to="/" /> : <StyledNLogo to="/" dark={isDarkModeContext} />}
          {isQueryMobile && (
            <NHamburger
              onClick={() => isMobileNavOpenFn(!isMobileNavOpen)}
              active={isMobileNavOpen}
            />
          )}
          <NavWrapper
            isMobileNavOpen={isMobileNavOpen}
            onMouseEnter={() => isTintFn(!isQueryMobile)}
            onMouseLeave={() => isTintFn(false)}
          >
            <Nav dark={isDarkModeContext}>
              {navigationData.map((item) =>
                item.name === 'Usługi' || item.name === 'Services' ? (
                  <NavItem
                    isTintFn={isTintFn}
                    isTint={isTint}
                    data={item.data}
                    to={item.link}
                    onClick={(e) => handleClick(e)}
                  >
                    {item.name}
                  </NavItem>
                ) : (
                  <NavItem isTintFn={isTintFn} isTint={isTint} data={item.data} to={item.link}>
                    {item.name}
                  </NavItem>
                ),
              )}
            </Nav>
            <Lang dark={isDarkModeContext} tint={isTint}>
              {Object.keys(lngs).map((lng) => (
                <LangItem
                  dark={isDarkModeContext}
                  key={lng}
                  className={i18n.resolvedLanguage === lng && 'active'}
                  type="submit"
                  onClick={() => i18n.changeLanguage(lng)}
                >
                  {lngs[lng].nativeName}
                </LangItem>
              ))}
            </Lang>
          </NavWrapper>
        </InnerWrapper>
      </Wrapper>
      {isTint && <TintBackground dark={isDarkModeContext} onClick={() => isTintFn(false)} />}
      {isMobileNavOpen && (
        <TintBackground dark={isDarkModeContext} onClick={() => isMobileNavOpenFn(false)} />
      )}
    </>
  );
};

export default NNavigation;
