import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import ArrowIcon from 'assets/Icons/arrowRightWhite.svg';
import { ThemeContext } from 'context';

const Wrapper = styled(NavLink)`
  display: flex;
  flex-shrink: 0;
  text-decoration: none;
  align-items: center;
  justify-content: space-between;
  transition: all 0.28s cubic-bezier(0.7, 0, 0.29, 1);

  border: 1px solid transparent;
  text-align: center;
  column-gap: 24px;
  background-color: #1c1c31;
  background-color: #282c3d;
  background-color: #3F4457;
  box-shadow: 0 14px 24px -7px #0008d912;
  padding: 14px 28px;
  border-radius: 8px;


  @media (max-width: 1024px) {
    /* padding: 16px; */
    max-width: unset;
  }
r
  &:hover{
    background-color: #143350;
  }
`;

const Label = styled.span`
  color: #fff;
  flex-grow: 1;
  line-height: 148%;
  font-size: 1.36rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  text-align: left;
  white-space: pre-line;

  @media (max-width: 1024px) {
    text-align: center;
    font-size: 1.25rem;
    white-space: nowrap;
  }

  ${({ dark }) => dark && css`
    color: rgba(242, 250, 252, 0.94);
  `}
`;

const Icon = styled.span`
  background-image: url(${ArrowIcon});
  background-repeat: no-repeat;
  width: 13px;
  height: 10px;
`;

const NButtonTertiary = ({
  children, icon, ...props
}) => {
  const isDarkModeContext = useContext(ThemeContext);

  return (
  <Wrapper dark={isDarkModeContext} {...props}>
    <Label dark={isDarkModeContext}>{ children }</Label>
    {icon && <Icon dark={isDarkModeContext} />}
  </Wrapper>
  );
};

export default NButtonTertiary;