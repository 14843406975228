import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ThemeContext } from 'context';

const Wrapper = styled(NavLink)`
  background-color: transparent;
  display: inline-block;
  text-decoration: none;
  transition: all 0.28s cubic-bezier(0.7, 0, 0.29, 1);
  border-bottom: 1px solid rgba(0,0,0,.5);
  padding: 5px 2px;

  ${({ dark }) => dark && css`
  border-bottom: 1px solid rgba(242, 250, 252, 0.94);
  `}

  ${({ colorParent }) => colorParent && css`
    border-bottom: 1px solid ${colorParent};
  `}

  @media (max-width: 1024px) {
    align-self: center;
    padding: 4px 2px;
  }
`;

const Label = styled.span`
  /* font-size: 1.5rem; */
  color: #000;
  font-weight: 400;
  flex-grow: 1;
  text-align: center;

  line-height: 148%;
  font-size: 1.36rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  ${({ dark }) => dark && css`
    color: rgba(242, 250, 252, 0.88);
  `}

  ${({ colorParent }) => colorParent && css`
    color: ${colorParent};
  `}

  @media (max-width: 1024px) {
    font-size: 1.25rem;
  }
`;

const NButtonSecondary = ({ children, ...props }) => {
  const isDarkModeContext = useContext(ThemeContext);
  return (
    <Wrapper {...props} dark={isDarkModeContext}>
        <Label dark={isDarkModeContext} {...props}>{ children }</Label>
    </Wrapper>
  );
};

export default NButtonSecondary;