import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import ArrowIcon from 'assets/Icons/arrowRightWhite.svg';
import { useTranslation } from 'react-i18next';

const Wrapper = styled(NavLink)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  margin: 10px 0;

  @media (max-width: 1024px) {
  }

  /* background-color: beige; */
  row-gap: 6px;
`;

const LabelWrapper = styled.div`
  color: #0c141d;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.44px;
  text-transform: uppercase;
  /* background-color: beige; */
`;

const LabelInnerWrapper = styled.span`
  display: inline-block;
  transform: translateX(32px);

  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
    transform: translateX(0);
  }
`;

const ButtonJobLabel = styled.div`
  transition: all 0.3s ease-in-out;
  background-color: white;
  border-radius: 44px;
  width: 80%;
  flex: 3;

  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  color: #212329;

  font-family: Inter;
  font-size: 13.6px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%; /* 19.448px */
  text-transform: uppercase;
`;

const ButtonWrapper = styled.div`
  transition: all 0.3s ease-in-out;

  /* background-color: azure; */
  padding: 4px;
  border-radius: 60px;
  height: 60px;
  background: linear-gradient(106deg, #2cff5a 0%, #36caff 80.84%, #0bf 144.36%),
    linear-gradient(97deg, #1e3edd 16.23%, #30ea9b 27.79%, #36caff 45.66%, #0bf 68.78%), #3f4457;

  &:hover {
    box-shadow: 0px 20px 45px 0px rgba(15, 14, 78, 0.09), 0px 101px 60px 0px rgba(15, 14, 78, 0.05),
      0px 179px 72px 0px rgba(15, 14, 78, 0.01), 0px 280px 78px 0px rgba(15, 14, 78, 0);

    /* background: linear-gradient(106deg, #2cff5a 0%, #36caff 130.87%, #0bf 144.36%), #3f4457; */

    ${ButtonJobLabel} {
      flex: 3.5;
    }
  }

  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  overflow: hidden;
`;

const ButtonAppLabel = styled.div`
  border-radius: 44px;
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;

  color: #212329;

  font-family: Inter;
  font-size: 13.6px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%; /* 19.448px */
  text-transform: uppercase;

  &::after {
    content: '';
    mask-image: url(${ArrowIcon});
    background-color: #212329;
    width: 13px;
    height: 10px;
  }
`;

const NButtonJob = ({ ...props }) => {
  const { t } = useTranslation(['heroHome']);

  return (
    <Wrapper {...props}>
      <LabelWrapper>
        <LabelInnerWrapper>{t('labeljob')}</LabelInnerWrapper>
      </LabelWrapper>
      <ButtonWrapper>
        <ButtonJobLabel>SAP® Basis Consultant</ButtonJobLabel>
        <ButtonAppLabel>{t('applabel')}</ButtonAppLabel>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default NButtonJob;
