import React from 'react';
import Heading from 'components/atoms/Typography/Headings';
import styled from 'styled-components';
import arrowForListIcon from 'assets/Icons/arrowForListIcon.svg';
import arrow from 'assets/Icons/arrow.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  @media (max-width: 1024px) {
    padding: 0;
  }
`;
const List = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin: 0;
  padding: 0;
  @media (max-width: 1024px) {
    row-gap: 12px;
  }
`;

const ListItem = styled.li`
  margin: 0;
  padding: 0;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;

  column-gap: 18px;
  color: #0C141D;

  font-size: 1.6rem;
  font-weight: 400;
  line-height: 154%;
  border-left: 1px solid ${({ theme }) => theme.accent};
  padding-left: 18px;

  /* &::before{
    content: '';
    width: 15px;
    height: 13px;
    background-image: url(${arrowForListIcon});
    background-repeat: no-repeat ;

    background-image: url(${arrow});
    width: 15px;
    height: 14px;
    margin-left: 8px;
    transition: transform 0.4s cubic-bezier(0.7, 0, 0.29, 1);
    background-repeat: no-repeat;
    background-position: center;
  } */

  @media (max-width: 1024px) {
    font-size: 1.4rem;
  }
`;

const JobArticle = ({
  className, title, items
}) => {
  const pallete = ({ theme }) => theme.blue900;

  return (
    <Wrapper className={className}>
      <Heading tag="h6" palette={pallete}>
        {title}
      </Heading>
      <List>
          {
            items?.map(
              (item) => (<ListItem key={item}>{item}</ListItem>)
            )
          }
      </List>
    </Wrapper>
  );
};

export default JobArticle;