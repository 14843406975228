import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`

  display: flex;
  padding: 24px 10px 0px;

  width: calc(100% - 160px);
  margin: 24px auto 0;
  max-width: 1920px;

  @media (max-width: 1024px) {
    width: calc(100% - 40px);
    aspect-ratio: unset;
    margin-top: 24px;
  }

`;

const Heading = styled.h4`
    grid-column: 2 / -1;
    font-size: 3.6rem;
    line-height: 122%;
    letter-spacing: -0.68px;
    font-weight: 400;

    font-size: 1.9rem;
    font-weight: 500;
    color: #070B11;
    color: rgba(12,20,29,0.88);
    letter-spacing: 0.07em;
    text-transform: uppercase;
    @media (max-width: 1024px) {
        font-size: 3.2rem;
        line-height: 128%;
        letter-spacing: -0.34px;
        font-weight: 400;

        font-size: 1.9rem;
        font-weight: 500;
        line-height: 118%;
        letter-spacing: 0.07em;
        color: #070B11;
        text-transform: uppercase;
        margin-bottom: 12px;
    }
`;

const PracticeHeading = ({ data }) => (
  <Wrapper>
    <Heading>
      {data[0].text}
    </Heading>
  </Wrapper>
);

export default PracticeHeading;