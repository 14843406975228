import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
    padding-right: 80px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1024px) {
        padding-right: 0;
        flex-direction: column;
        row-gap: 48px;
    }
`;

const Item = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 28px;
    @media (max-width: 1024px) {
        row-gap: 20px;
    }
`;

const Caption = styled(NavLink)`
    text-decoration: none;
    font-weight: 600;
    color: rgba(255, 255, 255, .9);
    font-size: 1.4rem;
    font-weight: 500;

    font-size: 1.6rem;
    letter-spacing: 0.02em;

    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 0.04em;
    text-transform: uppercase;
`;

const List = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 12px;
`;

const LinkItem = styled(NavLink)`
    font-size: 1.4rem;
    color: rgba(255,255,255, 0.7);
    text-decoration: none;

    display: flex;
    flex-direction: column;
    row-gap: 3px;
    overflow: hidden;
    transition: all 0.28s ease-in-out;

    &::after{
      content: '';
      width: 101%;
      height: 1px;
      border-bottom: 1px solid rgba(255,255,255, 0.7);
      transform: translateX(-101%);
      transition: transform 0.28s ease-in-out;
    }

    &:hover, &.active{
      cursor: pointer;
      color: rgba(255,255,255, 1);
      &::after{
        transform: translateX(0);
        width: 100%;
      }
    }
`;

const Sitemap = () => {
  const { t } = useTranslation(['sitemap']);
  const sitemap = t('sitemap:navigation', { returnObjects: true });

  return (
    <Wrapper>
        {
            sitemap.map((item) => (
                <Item>
                    <Caption>{item.name}</Caption>
                    <List>
                    {item.data?.map((list) => (
                        <LinkItem to={list.link}>{list.name}</LinkItem>
                    ))}
                    </List>
                </Item>
            ))
        }
    </Wrapper>
  );
};

export default Sitemap;