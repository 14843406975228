import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Head from 'Head/Head';
import Footer from 'components/organisms/Footer/Footer';
import NNavigation from 'components/organisms/Navigation/NNavigation';
import Breadcrumbs from 'components/organisms/Breadcrumbs/Breadcrumbs';
import ServicesTemplateContentA from 'templates/ServicesContentTemplate/ServicesContentTemplateA';
import ServicesTemplateContentB from 'templates/ServicesContentTemplate/ServicesContentTemplateB';
import ServicesTemplateContentC from 'templates/ServicesContentTemplate/ServicesContentTemplateC';
import ServicesTemplateContentD from 'templates/ServicesContentTemplate/ServicesContentTemplateD';
import ServicesTemplateContentE from 'templates/ServicesContentTemplate/ServicesContentTemplateE';

const helmetContext = {};

const ServicesTemplate = ({ darkModefn, data }) => {
  useEffect(() => {
    darkModefn(false);
  }, [darkModefn]);

  return (
  <>
    <HelmetProvider context={helmetContext}>
        <Head
          title={data.title}
          description={data.seoDesc}
        />
    </HelmetProvider>
    <NNavigation />
    <Breadcrumbs data={data.breadcrumbs} />
    {
      data.contentType === 'article'
      && (
        <ServicesTemplateContentA
          data={data}
          bgColor="linear-gradient(120deg,#16233d,#424653)"
        />
      )
    }
    {
      data.contentType === 'konsultingsap'
      && (
        <ServicesTemplateContentC
          data={data}
          bgColor="linear-gradient(135deg, #001331 0%, #9C8C8A 100%)"
        />
      )
    }
    {data.contentType === 'szkolenia' && <ServicesTemplateContentB data={data} />}
    {data.contentType === 'wirtualizacja' && <ServicesTemplateContentD data={data} />}
    {data.contentType === 'blog' && <ServicesTemplateContentE data={data} />}
    {data.contentType === 'managedservices' && <ServicesTemplateContentE data={data} />}
    <Footer />
  </>
  );
};

export default ServicesTemplate;
