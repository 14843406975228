import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import NavigationItemListSub from './NavigationListItemSub';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: fit-content;
    z-index: 2;
    background-color: #E8E9F3;
    padding: 20px 36px 48px 36px;
    border-radius:  0 0 2px 2px;

    position: absolute;
    width: max-content;

    @media (max-width: 1024px) {
        padding: 0;
        align-items: stretch;
        width: 100%;
        row-gap: 10px;
        margin-bottom: 20px;
        position: unset;
    }
`;

const NavItem = styled(NavLink)`
    display: flex;
    flex-direction: column;

    color: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0;
    text-decoration: none;
    overflow: hidden;
    row-gap: 10px;
    position: relative;
    font-weight: 600;

    @media (max-width: 1024px) {
        font-size: 1.5rem;
        font-weight: 500;
    }

  &::after{
        content: '';
        width: 101%;
        height: 1px;
        transform: translateX(0);
        transition: transform 0.28s ease-in-out;
        background-color: #BCC0C7;
    }

    ${({ active }) => active && css`
        font-weight: 400;
        @media (max-width: 1024px) {
            font-weight: 600;
            opacity: 1;
        }
    `}

    &::before{
        content: '1';
        position: absolute;
        width: 101%;
        height: 1px;
        background-color: #002afa;
        bottom: 0;
        z-index: 2;
        transform: translateX(-100%);
        transition: transform 0.28s ease-in-out;
    }

    &:hover, &.active{
        color: #002afa;
        font-weight: 600;
        &::before{
            transform: translateX(0%);
        }
    }
`;

const StyledNavItem = styled(NavLink)`
    display: flex;
    flex-direction: column;

    color: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0;
    text-decoration: none;
    overflow: hidden;
    row-gap: 10px;
    position: relative;
    font-weight: 600;
    /* color: #595959; */
    margin-top: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    /* color: #002afa; */

    @media (max-width: 1024px) {
        font-size: 1.5rem;
        font-weight: 500;
    }


    &:hover{
        /* color: #002afa; */
        /* font-weight: 600; */
        cursor: unset;
        &::before{
            transform: translateX(0%);
        }
    }
`;

const Wrapped = styled.div`
    margin-bottom: 14px;
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    padding-top: 4px;
    /* padding-left: 12px; */
`;

const NavigationItemList = ({ data, className }) => {
  const handleClick = (e) => {
    e.preventDefault();
  };

  return (
    <Wrapper className={className}>
        {data.map((item) => (
          item.data ? (
            <>
                <StyledNavItem to={item.link} onClick={(e) => handleClick(e)}>{item.name}</StyledNavItem>
                <Wrapped>
                    {item.data?.map((itemEl) => (
                        <NavigationItemListSub to={itemEl.link}>{itemEl.name}</NavigationItemListSub>
                    ))}
                </Wrapped>
            </>
          ) : (
            <NavItem to={item.link}>{item.name}</NavItem>
          )

        ))}
    </Wrapper>
  );
};

export default NavigationItemList;