import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Head from 'Head/Head';
import Footer from 'components/organisms/Footer/Footer';
import Jobs from 'components/organisms/Jobs/Jobs';
import NNavigation from 'components/organisms/Navigation/NNavigation';
import CarrerHero from 'components/organisms/Carrer/CarrerHero';
import PracticeHeading from 'components/organisms/Practice/PracticeHeading';
import { useTranslation } from 'react-i18next';

const helmetContext = {};

const CareerView = ({ darkModefn }) => {
  // const { t } = useTranslation('jobOffersData, career');
  const { t } = useTranslation(['jobOffersData', 'career']);
  const JobsData = t('jobOffersData:jobs', { returnObjects: true });
  const TitleData = t('jobOffersData:title', { returnObjects: true });
  const CareerHero = t('career:hero', { returnObjects: true });
  const CareerHeroButtons = t('career:buttons', { returnObjects: true });

  useEffect(() => {
    darkModefn(false);
  }, [darkModefn]);
  return (
    <HelmetProvider context={helmetContext}>
      <Head title="Infinisap Kariera" description="Wybierz karierę z Infinisap 2023" />
      <NNavigation />
      <CarrerHero data={CareerHero} buttons={CareerHeroButtons} />
      <PracticeHeading data={TitleData} />
      <Jobs data={JobsData} />
      <Footer />
    </HelmetProvider>
  );
};

export default CareerView;
