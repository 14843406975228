import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import ArrowIconNext from 'assets/Icons/arrowIconNext.svg';
import ArrowIconBack from 'assets/Icons/arrowIconBack.svg';

const Button = styled(NavLink)`
  border: 1.5px solid ${({ theme }) => theme.accent};
  border-radius: 8px;
  padding: 12px 28px;
  /* font-size: 1.4rem; */
  color: ${({ theme }) => theme.accent};
  background: transparent;
  text-decoration: none;
  transition: all 0.21s ease-in-out;
  cursor: pointer;

  line-height: 154%;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin: 12px 8px 0px;

  ${({ WithArrowNext }) => (WithArrowNext) && css`
    background-color: ${({ theme }) => theme.accent};
    color: ${({ theme }) => theme.white};
    ::after{
      content: '';
      display: inline-block;
      background-image: url(${ArrowIconNext});
      background-position: center;
      background-repeat: no-repeat;
      width: 14px;
      height: 10px;
      transition: transform 0.21s ease-in-out;
    }
    :hover{
      ::after{
        transform: translateX(4px);
      }
    }
  `}

  &:hover{
    color: ${({ theme }) => theme.white};
    background: ${({ theme }) => theme.accent};
  }

  @media (max-width: 1024px) {
    padding: 12px 34px;
    font-size: 1.3rem;
  }

  ${({ WithArrowBack }) => (WithArrowBack) && css`
    background-color: ${({ theme }) => theme.greyAccent};
    opacity: 0.4;
    color: ${({ theme }) => theme.greyAccent};
    border: 1.5px solid ${({ theme }) => theme.greyAccent};
    padding: 12.5px 20px;
    @media (max-width: 1024px) {
      padding: 12px 20px;
    }
    font-size: 1.3rem;
    ::after{
      content: '';
      display: inline-block;
      background-image: url(${ArrowIconBack});
      background-position: center;
      background-repeat: no-repeat;
      width: 14px;
      height: 10px;
      transition: transform 0.21s ease-in-out;
    }
    :hover{
      background-color: ${({ theme }) => theme.greyAccent};
      opacity: 0.4;
      ::after{
        transform: translateX(-4px);
      }
    }
  `}

  ${({ secondary }) => (secondary ? css`
    border: 1.5px solid ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.white};
      &:hover{
        color: ${({ theme }) => theme.grey900};
        background: ${({ theme }) => theme.white};
      }` : undefined
  )};

  ${({ tertiary }) => (tertiary && css`
    border: unset;
    border-radius: unset;
    border-bottom: 1px solid ${({ theme }) => theme.greyAccent};
    padding: 10px 0;
    color: ${({ theme }) => theme.greyAccent};
    &:hover{
      color: ${({ theme }) => theme.greyAccent};
      background: unset;
    }
    @media (max-width: 1024px) {
      padding: 10px 0;
      font-size: 1.3rem;
    }`
  )};

`;

export default Button;