import React from 'react';
import styled from 'styled-components';
import Image01 from 'assets/Services/konsultingsap.jpg';
import PropTypes from 'prop-types';
import NButtonSecondary from 'components/atoms/Buttons/NButtonSecondary';
import { useMediaQuery } from 'react-responsive';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  gap: 20px;
  min-height: 100px;
  width: calc(100% - 160px);
  margin: 0px auto 72px;
  max-width: 1920px;
  border-radius: 4px;
  margin-bottom: 121px;

  @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      padding: 24px 20px 8px;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
    }
`;

const ImageWrapper = styled.div`
  grid-column: 11 / -1;
  overflow: hidden;
  aspect-ratio: 3 / 4;
  border-radius: 4px;
  transform: translateX(-56px) translateY(36px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: transparent;

  @media (max-width: 1024px) {
    transform: unset;
    order: 0;
    aspect-ratio: 2 / 1;
  }

`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const ContainerWrapper = styled.div`
  grid-column: 1 / span 10;
  display: flex;
  flex-direction: column;
  row-gap: 96px;
  border-radius: 4px;
  padding: 56px 76px 96px 56px;
  background: rgba(255,255,255,0.4);
  border-left: unset;
  border-bottom: unset;
  border-right: unset;
  position: relative;

  @media (max-width: 1024px) {
    row-gap: 72px;
    padding: 36px 20px 72px 20px;
    order: 1;
  }

  &::before{
    content: '';
    position: absolute;
    width: 70%;
    height: 3px;
    top: 20px;
    background-color: #ff1bb1;
  }

  &::after{
    content: '';
    position: absolute;
    width: 56%;
    height: 7px;
    left: 300px;
    top: 8px;
    background-color: #3b82ff;
  }
`;

const ContainerInnerWrapper = styled.div`
  display: flex;
  row-gap: 56px;
  flex-direction: column;

  @media (max-width: 1024px) {
    row-gap: 36px;
  }
`;

const Heading = styled.h1`
  font-size: 4.8rem;
  line-height: 120%;
  font-weight: 300;
  letter-spacing: -1.04px;
  color: #0B121B;

  @media (max-width: 1024px) {
      font-size: 3.2rem;
      letter-spacing: -1.08px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;

const Paragraph = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 168%;
  color: #0B121B;
  column-count: 2;
  column-gap: 40px;
  max-width: 90%;

  @media (max-width: 1024px) {
    column-count: unset;
    max-width: unset;
  }
`;

const Buttons = styled.div`
  display: flex;
  column-gap: 44px;
  align-items: center;
  margin-top: 36px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 36px;
    margin-top: 0px;
  }
`;

const ServicesTemplateContentC = ({ data }) => {
  const modifySingleChars = (str) => str.replace(/ ([a-zA-Z]) /g, ' $1'.concat('\u00A0'));
  const isQueryMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  return (
  <Wrapper>
    <ContainerWrapper>
      <ContainerInnerWrapper>
        <Heading>{data.title}</Heading>
        <ContentWrapper>
        <Paragraph>
            {data.content.map((el) => modifySingleChars(el.paragraph))}
        </Paragraph>
        </ContentWrapper>
      </ContainerInnerWrapper>
      <Buttons>
        <NButtonSecondary colorParent="#0B121B" to="/kontakt">{data.ctaprimary}</NButtonSecondary>
      </Buttons>
    </ContainerWrapper>
    <ImageWrapper>
      <Image src={!isQueryMobile ? data.image : data.imageM} alt={data.title} />
    </ImageWrapper>
  </Wrapper>
  );
};

Image.propTypes = { src: PropTypes.string };
Image.defaultProps = { src: Image01 };

export default ServicesTemplateContentC;