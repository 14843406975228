import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Head from 'Head/Head';
import Footer from 'components/organisms/Footer/Footer';
import NNavigation from 'components/organisms/Navigation/NNavigation';
import AboutUsHero from 'components/organisms/AboutUs/AboutUsHero';
// import Breadcrumbs from 'components/organisms/Breadcrumbs/Breadcrumbs';
import AboutContent from 'components/organisms/AboutUs/AboutContent';

const helmetContext = {};

// const BreadcrumbsElements = [
//   { name: 'O nas', link: '/o-nas' },
// ];

const AboutUsView = ({ darkModefn }) => {
  useEffect(() => {
    darkModefn(false);
  }, [darkModefn]);
  return (
    <>
    <HelmetProvider context={helmetContext}>
    <Head
      title="Cyfrowa transformacja nie jest wydarzeniem, to podróż!"
      description="Firma Infinisap została założona w 2022 roku z ambitnym celem: zapewnienia wysokiej jakości usług SAP® Basis zgodnie ze standardami SAP® Best Practices"
    />
    </HelmetProvider>
    <NNavigation />
    <AboutUsHero />
    <AboutContent />
    <Footer />
    </>
  );
};

export default AboutUsView;
