import React from 'react';
import styled from 'styled-components';
// import JobFormApp from 'components/organisms/JobFormApp/JobFormApp';
import JobFormAppNotReadyYet from '../JobFormApp/JobFormAppNotReadyYet';

const Wrapper = styled.div`
    border-radius: 4px;
    padding: 44px 56px 72px;
    background: ${({ theme }) => theme.white};
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    max-width: 800px;
    min-width: 720px;

    @media (max-width: 1024px) {
        padding: 4px;
        max-width: unset;
        min-width: unset;
    }
`;

const ButtonClose = styled.button`
    position: absolute;
    top: 20px;
    right: 14px;
    background: transparent;
    border: 0;
    width: 22px;
    height: 12px;
    padding: 0;
    margin: 0;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.18s ease-in-out;
    &::before,
    &::after{
        content: '';
        height: 2px;
        width: 100%;
        background: ${({ theme }) => theme.greyAccent};
        transition: transform 0.28s ease-in-out;
        transform-origin: center;
    }
    &::before{
        transform: translateY(5px) rotate(45deg);
    }
    &::after{
        transform: translateY(-5px) rotate(-45deg);
    }
    &:hover{
        cursor: pointer;
        transform: rotate(90deg);
    }

    @media (max-width: 1024px) {
        top: 30px;
        right: 24px;
    }
`;

const Jobform = ({
  className, jobFormOpenFn, title
}) => (
    <Wrapper className={className}>
        <ButtonClose onClick={() => jobFormOpenFn(false)} />
        {/* <JobFormApp title={title} /> */}
        <JobFormAppNotReadyYet title={title} />
    </Wrapper>
);

export default Jobform;