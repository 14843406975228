import React from 'react';
import styled from 'styled-components';
import image004 from 'assets/Images/Image004@.svg';
import image007 from 'assets/Images/Image007.svg';
import image008 from 'assets/Images/Image008.svg';
import NButtonPrimary from 'components/atoms/Buttons/NButtonPrimary';
import NButtonSecondary from 'components/atoms/Buttons/NButtonSecondary';
import NButtonTertiary from 'components/atoms/Buttons/NButtonTertiary';
import { useTranslation } from 'react-i18next';
import NButtonJob from 'components/atoms/Buttons/NButtonJob';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 20px;
  width: 100vw;
  aspect-ratio: 2.76 / 1;
  padding: 36px 0;

  width: calc(100% - 160px);
  margin: 0px auto;
  max-width: 1920px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    aspect-ratio: unset;
    padding: 0 20px 44px 20px;
    width: 100%;
  }
`;

const Content = styled.div`
  height: 100%;
  grid-column: 2 / span 4;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 36px;
  @media (max-width: 1024px) {
  }
`;

const Heading = styled.h1`
  font-size: 5.4rem;
  font-weight: 300;
  color: #0b121b;
  line-height: 120%;
  letter-spacing: -1.04px;
  white-space: pre-line;

  @media (max-width: 1024px) {
    font-size: 3.6rem;
    letter-spacing: -1.08px;
  }
`;

const Description = styled.p`
  font-size: 1.6rem;
  font-size: 1.76rem;
  font-weight: 400;
  line-height: 158%;
  color: #0c141d;

  @media (max-width: 1024px) {
    font-size: 1.4rem;
  }
`;

const Buttons = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
  margin-top: 36px;
  width: 100%;
  /* background-color: red; */
  display: grid;

  max-width: 620px;

  grid-template-columns: 2fr max-content;
  grid-template-rows: repeat(2, 1fr);

  row-gap: 20px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 0px;
  }
`;

const ButtonsCta = styled.div`
  /* background: yellow; */
  display: flex;
  column-gap: 20px;
  /* justify-content: space-between; */

  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 20px;
  }
`;

// const ButtonJob = styled.div`
//   grid-column: 1;
//   grid-row: 2;

//   display: flex;
//   justify-content: center;
// `;

const ButtonMission = styled.div`
  /* background: pink; */

  grid-column: 2;
  grid-row: 1;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

const Image = styled.div`
  grid-column: 6 / span 4;
  background-image: url(${image007});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  @media (min-width: 1920px) {
    background-size: 80%;
    background-position: center;
  }
  @media (max-width: 1024px) {
    order: -1;
    min-height: 210px;
    background-size: contain;
    background-position: right center;
    background-image: url(${image004});
    background-image: url(${image008});
  }
`;

const StyledNButtonPrimary = styled(NButtonPrimary)`
  flex: 1;
`;

const StyledNButtonTertiary = styled(NButtonTertiary)`
  flex: 1;
`;

const HeroHome = () => {
  const { t } = useTranslation(['heroHome']);

  return (
    <Wrapper>
      <Content>
        <Heading>{t('heading')}</Heading>
        <Description>{t('description')}</Description>
        <Buttons>
          <ButtonsCta>
            <StyledNButtonPrimary to="/uslugi/konsulting-sap" icon>
              {t('labelPrimary')}
            </StyledNButtonPrimary>
            <StyledNButtonTertiary to="/uslugi/managed-services" icon>
              {t('labelTertiary')}
            </StyledNButtonTertiary>
          </ButtonsCta>
          <NButtonJob to="/kariera/sap-basis-konsultant" />
          <ButtonMission>
            <NButtonSecondary to="/misja-firmy">{t('labelSecondary')}</NButtonSecondary>
          </ButtonMission>
        </Buttons>
      </Content>
      <Image />
    </Wrapper>
  );
};

export default HeroHome;
