import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Head from 'Head/Head';
import Footer from 'components/organisms/Footer/Footer';
import NNavigation from 'components/organisms/Navigation/NNavigation';
import HeroArticle from 'components/organisms/Article/HeroArticle/HeroArticle';
import ContentArticle from 'components/organisms/Article/HeroArticle/ContentArticle';
import Breadcrumbs from 'components/organisms/Breadcrumbs/Breadcrumbs';

const helmetContext = {};

const ServicesView = ({ darkModefn, data }) => {
  useEffect(() => {
    darkModefn(false);
  }, [darkModefn]);
  return (
  <HelmetProvider context={helmetContext}>
      <Head
        title={data.title}
        description={data.text}
      />
      <NNavigation />
      <Breadcrumbs data={[
        { name: 'Strona główna', link: '/' },
        { name: data.title, link: data.path },
      ]}
      />
      <HeroArticle
        imageXL={data.imageXL}
        imageXLM={data.imageXLM}
      />
      <ContentArticle
        category={data.category}
        title={data.title}
        text={data.text}
      />
      <Footer />
  </HelmetProvider>
  );
};

export default ServicesView;
