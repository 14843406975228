import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Head from 'Head/Head';
import Footer from 'components/organisms/Footer/Footer';
import Jobs from 'components/organisms/Jobs/Jobs';
import NNavigation from 'components/organisms/Navigation/NNavigation';
import Breadcrumbs from 'components/organisms/Breadcrumbs/Breadcrumbs';
// import PracticeHero from 'components/organisms/Practice/PracticeHero';
import PracticeHeading from 'components/organisms/Practice/PracticeHeading';
import { useTranslation } from 'react-i18next';

const helmetContext = {};

const PracticeView = ({ data, darkModefn }) => {
  const { t } = useTranslation('practiceOffersData');
  const BreadcrumbsData = t('practiceOffersData:breadcrumbs', { returnObjects: true });
  // const HeroData = t('practiceOffersData:hero', { returnObjects: true });
  const TitleData = t('practiceOffersData:title', { returnObjects: true });

  useEffect(() => {
    darkModefn(false);
  }, [darkModefn]);
  return (
    <>
      <HelmetProvider context={helmetContext}>
        <Head title="Infinisap Kariera" description="Wybierz karierę z Infinisap 2023" />
      </HelmetProvider>
      <NNavigation />
      <Breadcrumbs data={BreadcrumbsData} />
      {/* <PracticeHero data={HeroData} /> */}
      <PracticeHeading data={TitleData} />
      <Jobs data={data} />
      <Footer />
    </>
  );
};

export default PracticeView;
