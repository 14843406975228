import styled from 'styled-components';
import LinkedInIcon from 'assets/Icons/linkedIn.svg';
import { NavLink } from 'react-router-dom';

const Wrapper = styled(NavLink)`
    position: relative;
    width: 26px;
    height: 26px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    overflow: hidden;

    img{
        z-index: 1;
        width: 100%;
    }

    &:after{
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #0077B5;
        z-index: 0;
        transform: translateX(-100%);
        transition: transform 0.28s ease-in-out;
    }

    &:hover{
        cursor: pointer;
        &::after{
            transform: translateX(0%);
        }
    }
`;

const LinkedIn = () => (
    <Wrapper
      to="https://www.linkedin.com/company/infinisap-sp-zo-o/?viewAsMember=true"
      target="_blank"
      rel="noopener noreferrer"
    >
        <img
          src={LinkedInIcon}
          alt="LinkedIn"
        />
    </Wrapper>
);

export default LinkedIn;