import React from 'react';
// import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Image01 from 'assets/Services/migracja-do-s4hana.jpg';
import NButtonSecondary from 'components/atoms/Buttons/NButtonSecondary';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  gap: 20px;
  min-height: 100px;
  width: calc(100% - 160px);
  margin: 0px auto 72px;
  max-width: 1920px;
  border-radius: 4px;
  margin-bottom: 121px;

  @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      padding: 24px 20px 8px;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
    }
`;

const ImageWrapper = styled.div`
  grid-column: 11 / -1;
  overflow: hidden;
  aspect-ratio: 3 / 4;
  border-radius: 4px;
  transform: translateX(-56px) translateY(36px);
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    transform: unset;
    order: 0;
    aspect-ratio: 2 / 1;
  }
`;

const FadeIn2 = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Image = styled.img`
  width: 100%;
  animation: .2s ${FadeIn2} ease-in-out;
`;

const ContainerWrapper = styled.div`
  grid-column: 1 / span 10;
  display: flex;
  flex-direction: column;
  row-gap: 96px;
  border-radius: 4px;
  padding: 56px 76px 96px 56px;
  background: ${({ bgColor }) => bgColor};

  @media (max-width: 1024px) {
    row-gap: 72px;
    padding: 36px 20px 72px 20px;
    order: 1;
  }
`;

const ContainerInnerWrapper = styled.div`
  display: flex;
  row-gap: 56px;
  flex-direction: column;

  @media (max-width: 1024px) {
    row-gap: 36px;
  }
`;

const Heading = styled.h1`
  font-size: 4.8rem;
  line-height: 120%;
  font-weight: 300;
  letter-spacing: -1.04px;
  color: #e4d5d6;

  @media (max-width: 1024px) {
      font-size: 3.2rem;
      letter-spacing: -1.08px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;

const Paragraph = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 168%;
  color: #e4d5d6;
  column-count: 2;
  column-gap: 40px;
  max-width: 90%;
  white-space: pre-line;

  @media (max-width: 1024px) {
    column-count: unset;
    max-width: unset;
  }
`;

const Buttons = styled.div`
  display: flex;
  column-gap: 44px;
  align-items: center;
  margin-top: 36px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 36px;
    margin-top: 0px;
  }
`;

// const FadeIn1 = keyframes`
//   from {
//     transform: rotate(0);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// `;

// const Preload = styled.div`
//   border: 1px solid grey;
//   border-left: 1px solid transparent;

//   grid-column: 6 / span 1;
//   aspect-ratio: 1 / 1;
//   animation: .5s ${FadeIn1} ease-in-out infinite;
//   border-radius: 20px;
//   width: 24px;
//   height: 24px;
//   grid-column: 8;
//   align-self: center;
//   justify-self: center;
// `;

const ServicesTemplateContentA = ({ data, bgColor }) => {
  // const [isLoad, isLoadFn] = useState(false);
  const modifySingleChars = (str) => str.replace(/ \b([a-zA-Z]{1,3})\b /g, ' $1'.concat('\u00A0'));
  const isQueryMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  // useEffect(() => {
  //   setTimeout(() => {
  //     isLoadFn(true);
  //   }, 1200);
  // });
  return (
  <Wrapper>
    <ContainerWrapper bgColor={bgColor}>
      <ContainerInnerWrapper>
        <Heading>{data.title}</Heading>
        <ContentWrapper>
        <Paragraph>
            {data.content.map((el) => modifySingleChars(el.paragraph))}
        </Paragraph>
        </ContentWrapper>
      </ContainerInnerWrapper>
      <Buttons>
        <NButtonSecondary colorParent="#e4d5d6" to="/kontakt">{data.ctaprimary}</NButtonSecondary>
      </Buttons>
    </ContainerWrapper>
    <ImageWrapper>
      <Image src={!isQueryMobile ? data.image : data.imageM} alt={data.title} />
    </ImageWrapper>
  </Wrapper>
  );
};

Image.propTypes = { src: PropTypes.string };
Image.defaultProps = { src: Image01 };

export default ServicesTemplateContentA;