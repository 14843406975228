import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.section`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: 44px 10px;
    width: calc(100% - 160px);
    gap: 56px;

    max-width: 1920px;
    margin: 0 auto;
    margin: 0px auto 144px;

    @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        gap: 36px;
    }

    /* background-color: aqua; */
`;

const InnerWrapper = styled.div`
  min-height: 200px;
  grid-column: 6 / span 6;

  width: auto;
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  @media (max-width: 1024px) {
      row-gap: 36px;
      order: 0;
  }
`;

const HeaderWrapper = styled.div`
    row-gap: 8px;
    grid-column: 1 / span 5;
    flex-direction: column;
    row-gap: 32px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    @media (max-width: 1024px) {
        margin-top: 4px;
        margin-bottom: 0px;
        order: 1;
    }
`;

const HeadingInnerWrapper = styled.div`
    padding-top: 4px;
    justify-self: end;
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    align-items: flex-start;

    @media (max-width: 1024px) {
        /* row-gap: 0; */
    }
`;

const Heading = styled.h1`
    background-color: #0C141D;
    letter-spacing: -0.68px;
    white-space: pre-line;
    font-size: 1.76rem;
    font-weight: 400;
    line-height: 164%;
    color: #fff;
    display: inline;
    align-self: flex-end;
    padding: 4px 10px;
    border-radius: 4px;

    @media (max-width: 1024px) {
        font-size: 1.6rem;
        line-height: 128%;
        letter-spacing: -0.34px;
        font-weight: 400;
        padding: 8px 16px;
        align-self: flex-start;
    }
`;

const Paragraph = styled.p`
    column-count: 2;
    column-gap: 40px;
    color: rgba(7, 11, 17, 0.9);

    font-size: 1.6rem;
    font-weight: 400;
    line-height: 162%;
    color: rgba(12, 20, 29, 0.9);
    white-space: pre-line;

    br{
        display: none;
    }

    @media (max-width: 1024px) {
        column-count: 1;
        br{
           display: block;
        }
    }
`;

const AboutContent = () => {
  const modifySingleChars = (str) => str.replace(/ \b([a-zA-Z]{1,3})\b /g, ' $1'.concat('\u00A0'));
  const { t } = useTranslation(['aboutUs']);
  return (
  <Wrapper>
    <HeaderWrapper>
        <HeadingInnerWrapper>
            <Heading>
                {t('content.cite1')}
            </Heading>
            <Heading>
                {t('content.cite2')}
            </Heading>
        </HeadingInnerWrapper>
    </HeaderWrapper>
    <InnerWrapper>
      <Paragraph>{modifySingleChars(t('content.text'))}</Paragraph>
    </InnerWrapper>
  </Wrapper>
  );
};

export default AboutContent;