import React from 'react';
import styled from 'styled-components';
import item2 from 'assets/AboutUs/item1.jpg';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 0;
  width: calc(100% - 160px);
  gap: 56px;
  max-width: 1920px;
  margin: 44px auto 0;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    aspect-ratio: unset;
    padding: 0 20px 44px 20px;
    width: 100%;
    gap: 24px;
  }

`;

const Item1 = styled.div`
  grid-column: 1 / span 5;
  background-color: transparent;
  width: 100%;
  height: 100%;
  border-top: 1px solid #182B55;
  border-bottom: 1px solid #182B55;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1024px) {
    padding: 20px 0;
  }
`;

const Item3 = styled.div`
  grid-column: 12 / span 1;
  background-color: transparent;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #182B55;
  border-top: 1px solid #182B55;

  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    display: none;
  }

`;

const Item2 = styled.img`
  grid-column: 6 / span 6;
  aspect-ratio: 2 / 1;
  width: 100%;
  border-radius: 4px;
`;

const Heading = styled.h1`
  font-size: 4.8rem;
  font-weight: 300;
  color: #0B121B;
  line-height: 120%;
  letter-spacing: -1.04px;
  text-align: right;

  @media (max-width: 1024px) {
      font-size: 3.6rem;
      letter-spacing: -1.08px;
  }
`;

const AboutUsHero = () => {
  const { t } = useTranslation(['aboutUs']);
  return (
  <Wrapper>
    <Item1>
      <Heading>{t('hero.heading')}</Heading>
    </Item1>
    <Item2 src={item2} />
    <Item3 />
  </Wrapper>
  );
};

export default AboutUsHero;