import C1 from 'assets/Cert/SAP_HANA_20_SPS06.png';
import C2 from 'assets/Cert/E_S4HCON2022.png';
import C3 from 'assets/Cert/C_FIORADM_21.png';
import C4 from 'assets/Cert/C_HANAIMP_17.png';
import C5 from 'assets/Cert/C_HANATEC_17.png';
import C6 from 'assets/Cert/C_SM100_7208.png';
import C7 from 'assets/Cert/C_SM100_7210.png';
import C8 from 'assets/Cert/C_TADM55A_75.png';
import C9 from 'assets/Cert/SAP_Authorized_Trainer_2021.png';
import C10 from 'assets/Cert/SAP_Certified_Associate.png';
import C11 from 'assets/Cert/SAP_HANA_20.png';
import C12 from 'assets/Cert/SAP_S4HANA_Conversion_Upgrade.png';
import C13 from 'assets/Cert/SAP_S4HANA_Implementation_Scenarios.png';
import C14 from 'assets/Cert/SAP_S4HANA_System_Administration.png';
import C15 from 'assets/Cert/SAP_Solution_Manager.png';
import C16 from 'assets/Cert/OS_DB_Migration.png';

export const CertData = [
  {
    image: C16,
    alt: 'SAP_OS/DB Migration for SAP NetWeaver 7.52',
    border: true,
  },
  {
    image: C1,
    alt: 'SAP_HANA_20_SPS06',
    border: true,
  },
  {
    image: C9,
    alt: 'SAP_Authorized_Trainer_2021',
    noShadow: true,
  },
  {
    image: C7,
    alt: 'C_SM100_7210',
  },
  {
    image: C2,
    alt: 'E_S4HCON2022',
  },
  {
    image: C3,
    alt: 'C_FIORADM_21',
  },
  {
    image: C4,
    alt: 'C_HANAIMP_17',
  },
  {
    image: C5,
    alt: 'C_HANATEC_17',
  },
  {
    image: C6,
    alt: 'C_SM100_7208',
  },
  {
    image: C8,
    alt: 'C_TADM55A_75',
  },
  {
    image: C10,
    alt: 'SAP_Certified_Associate',
  },
  {
    image: C11,
    alt: 'SAP_HANA_20',
  },
  {
    image: C12,
    alt: 'SAP_S4HANA_Conversion_Upgrade',
  },
  {
    image: C13,
    alt: 'SAP_S4HANA_Implementation_Scenarios',
  },
  {
    image: C14,
    alt: 'SAP_S4HANA_System_Administration',
    border: true,
  },
  {
    image: C15,
    alt: 'SAP_Solution_Manager',
  },
];
