import React from 'react';
import styled from 'styled-components';
import HeroImage01 from 'assets/Recruitment/HeroImage01.svg';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);

  column-gap: 20px;
  width: 100vw;
  aspect-ratio: 4.4 / 1;

  width: calc(100% - 160px);
  margin: 0px auto;
  max-width: 1920px;
  background-color: #282c3d;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    padding: 44px 20px 36px;
    grid-template-columns: repeat(2, 1fr);
    width: calc(100% - 40px);
    aspect-ratio: unset;
    margin-top: 24px;
    row-gap: 24px;

    /* display: none; */
  }

`;

const Content = styled.div`
  height: 100%;
  grid-column: 2 / span 5;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 36px;
  @media (max-width: 1024px) {
    order: 1;
  }
`;

const Heading = styled.h1`
  font-weight: 300;
  color: #5ec6ff;
  line-height: 120%;
  letter-spacing: -1.04px;

  font-size: 4.6rem;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: -1.04px;

  @media (max-width: 1024px) {
      font-size: 2.2rem;
      letter-spacing: -1.08px;
      line-height: 138%;
      font-weight: 400;
  }

  white-space: pre-line;
  &::first-line{
    color: #fff;
  }
`;

const ImageWrapper = styled.div`
  grid-column: 7 / span 3;
`;

const Image = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  mask-image: url(${HeroImage01});
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: #99d3ff;
  mask-size: 54%;

  @media (max-width: 1024px) {
    order: 0;
    width: 100%;
    height: 110px;
    position: unset;
    mask-size: contain;
    mask-position: center;
  }
`;

const RecruitmentHero = ({ data }) => (
  <Wrapper>
    <Content>
      <Heading>
        {data[0].heading}
      </Heading>
    </Content>
    <ImageWrapper>
      <Image />
    </ImageWrapper>
  </Wrapper>
);

export default RecruitmentHero;