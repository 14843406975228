import { useContext } from 'react';
import styled, { css } from 'styled-components';
import HeroMissionImage01 from 'assets/Images/HeroMissionImage01.svg';
import { ThemeContext } from 'context';
import NButtonSecondary from 'components/atoms/Buttons/NButtonSecondary';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 20px;
  width: 100vw;
  aspect-ratio: 2.76 / 1;
  padding: 36px 0;

  width: calc(100% - 160px);
  margin: 0px auto;
  max-width: 1920px;

  margin-bottom: 72px;
  border-radius: 4px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    aspect-ratio: unset;
    padding: 0 20px 44px 20px;
    width: 100%;
  }

`;

const Content = styled.div`
  height: 100%;
  grid-column: 2 / span 4;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 36px;
  @media (max-width: 1024px) {

  }
`;

const Heading = styled.h1`
  font-size: 5.4rem;
  font-weight: 300;
  color: #0B121B;
  line-height: 120%;
  letter-spacing: -1.04px;

  @media (max-width: 1024px) {
      font-size: 3.4rem;
      letter-spacing: -1.08px;
  }

  ${({ dark }) => dark && css`
    color: rgba(242, 250, 252, 0.88);
  `}

`;

const Description = styled.p`
  font-size: 1.6rem;
  font-size: 1.76rem;
  font-weight: 400;
  line-height: 158%;
  color: #0C141D;

  @media (max-width: 1024px) {
      font-size: 1.4rem;
  }

  ${({ dark }) => dark && css`
    color: rgba(242, 250, 252, 0.88);
  `}
`;

const Buttons = styled.div`
  display: flex;
  column-gap: 44px;
  align-items: center;
  margin-top: 36px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 36px;
    margin-top: 0px;
  }
`;

const Image = styled.div`
  grid-column: 7 / span 5;
  background-size: 84%;
  background-image: url(${HeroMissionImage01});
  background-repeat: no-repeat;
  background-position: left center;
  @media (min-width: 1920px) {
    background-size: 87%;
    background-position: left center;
  }
  @media (max-width: 1024px) {
    order: -1;
    min-height: 220px;
    background-size: contain;
    background-position: right center;
    background-image: url(${HeroMissionImage01});
  }
`;

const HeroMission = () => {
  const isDarkModeContext = useContext(ThemeContext);
  const { t } = useTranslation(['mission']);
  const modifySingleChars = (str) => str.replace(/ \b([a-zA-Z]{1,3})\b /g, ' $1'.concat('\u00A0'));

  return (
  <Wrapper>
    <Content>
      <Heading dark={isDarkModeContext}>{t('hero.heading')}</Heading>
      <Description dark={isDarkModeContext}>{modifySingleChars(t('hero.text'))}</Description>
      <Buttons>
        <NButtonSecondary to={t('hero.cta.link')}>{t('hero.cta.label')}</NButtonSecondary>
      </Buttons>
    </Content>
    <Image />
  </Wrapper>
  );
};

export default HeroMission;