import { useContext } from 'react';
import styled from 'styled-components';
import nlogo from 'assets/Logo/NLogo.svg';
import nlogoDarkMode from 'assets/Logo/NLogoDarkMode.svg';
import { NavLink } from 'react-router-dom';
import { ThemeContext } from 'context';

const Wrapper = styled(NavLink)`
  display: flex;
`;

const NLogo = ({
  className, dark, ...props
}) => {
  const isDarkModeContext = useContext(ThemeContext);

  return (
  <Wrapper className={className} dark={isDarkModeContext} {...props}>
    {dark
      ? (<img src={nlogoDarkMode} width="172px" alt="Logo Infinisap" />
      ) : (
      <img src={nlogo} width="172px" alt="Logo Infinisap" />)}
  </Wrapper>
  );
};

export default NLogo;