import React from 'react';
import styled, { css } from 'styled-components';
// import PropTypes from 'prop-types';

const HeadingRoot = ({
  className,
  tag: Tag,
  children,
}) => (
      <Tag className={className}>{children}</Tag>
);

const Heading = styled(HeadingRoot)`
    --palette: ${({ theme }) => theme.white};
    color: var(--palette);
    color: #0C141D;
    font-size: 9.6rem;
    line-height: 114%;
    letter-spacing: -0.06em;
    font-weight: 400;

    ${({ palette }) => (palette && css`
    --palette: ${palette};`
  )};

    ${({ tag }) => tag === 'h1' && css`
      letter-spacing: -0.026em;
      font-weight: 400;
      line-height: 110px;
    `}

    ${({ tag }) => tag === 'h2' && css`
      font-size: 6.2rem;
      line-height: 118%;
      letter-spacing: -0.03;
      @media (max-width: 1600px) {
        font-size: 4.8rem;
        letter-spacing: -0.03em;
      }
      @media (max-width: 1024px) {
        font-size: 3.0rem;
        letter-spacing: -0.03em;
      }
    `}

    ${({ tag }) => tag === 'h3' && css`
      font-size: 3.6rem;
      line-height: 118%;
      letter-spacing: -0.04em;

      @media (max-width: 1024px) {
        font-size: 2.8rem;
        letter-spacing: -0.03em;
      }
    `}

    ${({ tag }) => tag === 'h4' && css`
      font-size: 2.4rem;
      line-height: 118%;
      letter-spacing: -0.01em;
      font-weight: 600;

      @media (max-width: 1024px) {
        font-size: 2.4rem;
        letter-spacing: -0.01em;
      }
    `}

    ${({ tag }) => tag === 'h5' && css`
      font-size: 6.1rem;
      line-height: 118%;
      letter-spacing: -0.01em;
      font-weight: 700;

      @media (max-width: 1024px) {
        font-size: 4.5rem;
      }
    `}

    ${({ tag }) => tag === 'h6' && css`
      font-size: 2.2rem;
      line-height: 140%;
      letter-spacing: 0em;
      font-weight: 600;
      @media (max-width: 1024px) {
        font-size: 2rem;
      }

    `}

`;

// Heading.propTypes = { tag: PropTypes.string };
// Heading.propTypes = { children: PropTypes.string };
// Heading.defaultProps = { tag: 'h2' };
// Heading.defaultProps = { children: 'Rozwój, nauka, kariera. Technologia SAP®' };

export default Heading;
