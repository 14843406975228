import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Head from 'Head/Head';
import Footer from 'components/organisms/Footer/Footer';
import NNavigation from 'components/organisms/Navigation/NNavigation';
import Cert from 'components/organisms/Cert/Cert';
import Breadcrumbs from 'components/organisms/Breadcrumbs/Breadcrumbs';
import AboutUsTemplate from 'templates/AboutUsTemplate/AboutUsTemplate';
import { useTranslation } from 'react-i18next';

const helmetContext = {};

// const BreadcrumbsElements = [
//   { name: 'Certyfikaty', link: '/certyfikaty' },
// ];

const CertView = ({ darkModefn }) => {
  const { t } = useTranslation(['certification']);
  const bread = t('certification:breadcrumbs', { returnObjects: true });

  useEffect(() => {
    darkModefn(false);
  }, [darkModefn]);
  return (
    <>
    <HelmetProvider context={helmetContext}>
      <Head
        title="Certyfikowani specjaliści systemów SAP®"
        description="Posiadamy 20 lat doświadczenia w pracy z SAP® Basis."
      />
    </HelmetProvider>
    <NNavigation />
    <Breadcrumbs data={bread} />
    <AboutUsTemplate />
    <Cert showAll="true" />
    <Footer />
    </>
  );
};

export default CertView;
