import styled from 'styled-components';

const Wrapper = styled.div`
    margin-bottom: 144px;
`;

const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 56px;

    width: 100vw;

    width: calc(100% - 160px);
    margin: 0 auto;
    max-width: 1920px;

    @media (max-width: 1024px) {
        padding: 0;
        width: calc(100% - 40px);
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    @media (max-width: 1024px) {
    }
`;

const Caption = styled.span`
    font-size: 1.2rem;
    font-weight: 500;
    color: rgba(12, 20, 29, 0.7);
    letter-spacing: 0.07em;
    text-transform: uppercase;
`;

const Heading = styled.h2`
    font-size: 3.2rem;
    letter-spacing: -0.68px;
    line-height: 122%;
    font-weight: 500;
    color: #070B11;
`;

const Paragraph = styled.p`
    column-count: 3;
    column-gap: 40px;

    font-size: 1.5rem;
    font-weight: 400;
    line-height: 162%;
    color: rgba(12, 20, 29, 0.9);
    white-space: pre-line;

    br{
        display: none;
    }

    @media (max-width: 1024px) {
        column-count: 1;
        white-space: pre-line;
        br{
           display: block;

        }
    }
`;

const ContentArticle = ({
  category, title, text
}) => {
  const modifySingleChars = (str) => str.replace(/ (\b[a-zA-Z]{1,2}\b) /g, ' $1'.concat('\u00A0'));
  return (
    <Wrapper>
        <InnerWrapper>
            <HeaderWrapper>
                <Caption>{category}</Caption>
                <Heading>{title}</Heading>
            </HeaderWrapper>
            <Paragraph>
                {modifySingleChars(text)}
            </Paragraph>
        </InnerWrapper>
    </Wrapper>
  );
};

export default ContentArticle;