import React from 'react';
import styled from 'styled-components';
import JobHeroImage from 'assets/Images/JobHeroImage.jpg';
import JobHeroImage2 from 'assets/Images/JobHeroImage2.jpg';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: 1fr;

  column-gap: 20px;
  width: 100vw;
  aspect-ratio: 2.76 / 1;
  aspect-ratio: 4 / 1;
  padding: 36px 0;

  width: calc(100% - 160px);
  margin: 0px auto;
  max-width: 1920px;
  background-color: transparent;
  background-image: url(${JobHeroImage});
  background-size: contain;
  border-radius: 4px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    padding: 0 20px 44px 20px;
    width: 100%;

    /* background-image: unset; */
    background-color: #0f273e;
  }

  @media (max-width: 1024px) {
    row-gap: 24px;
    display: flex;
    flex-direction: column;
    padding: 44px 20px 36px;
    grid-template-columns: repeat(2, 1fr);
    width: calc(100% - 40px);
    aspect-ratio: unset;
    margin-top: 24px;
    background-image: url(${JobHeroImage2});
    aspect-ratio: 1.44 / 1;
  }

`;

const Content = styled.div`
  height: 100%;
  grid-column: 3 / -1;
  grid-row: 1 / span 2;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 18px;

  /* border-left: 1px solid ${({ theme }) => theme.white}; */
  /* padding-left: 36px; */

  @media (max-width: 1024px) {

  }
`;

const Heading = styled.h1`
  font-size: 4.8rem;
  font-weight: 00;
  color: #fff;
  line-height: 120%;
  letter-spacing: -1.04px;

  @media (max-width: 1024px) {
      font-size: 3.6rem;
      letter-spacing: -1.08px;
  }
`;

const Paragraph = styled.p`
    column-gap: 20px;
    color: #fff;
    padding: 0 8px;

    font-size: 1.9rem;
    font-weight: 400;
    line-height: 154%;
`;

const HeroJobTemplate = ({ title, location }) => (
  <Wrapper>
    <Content>
      <Heading>
        {title}
      </Heading>
      <Paragraph>{location}</Paragraph>
    </Content>
  </Wrapper>
);

export default HeroJobTemplate;