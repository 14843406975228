import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 10px;
  width: 100%;
  border-bottom: 1px solid rgba(0,0,0,.2);
  padding: 24px 0;

  &:last-child{
    border: unset;
  }

  @media (max-width: 1024px) {
    padding: 20px 0;
  }
`;

const Heading = styled.h4`
  font-size: 1.9rem;
  font-weight: 500;
  line-height: 118%;
  letter-spacing: 0.07em;
  color: #070B11;
  text-transform: uppercase;
  margin-bottom: 12px;
`;

const List = styled.ol`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: section;
`;

const SubList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding: 12px 44px;
  list-style-type: lower-latin;
  list-style-position: outside;
`;

const ListItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    column-gap: 20px;
    color: rgba(12, 20, 29, 0.88);
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 148%;

    @media (max-width: 1024px) {
      font-size: 1.4rem;
    }
`;

const ListItem = styled.li`
    display: flex;
    column-gap: 12px;
    white-space: pre-line;
    &::before{
      counter-increment: section;
      content: " " counter(section) ". ";
    }
    @media (max-width: 1024px) {
      font-size: 1.4rem;
    }
`;

const Paragraph = styled.p`
  color: rgba(12, 20, 29, 0.88);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 148%;
  white-space: pre-line;
`;

const ArticleItem = ({
  className,
  data
}) => (
  <Wrapper className={className}>
        {data.title && (<Heading>{data.title}</Heading>)}
        {data.paragraphs?.map((elp) => (
          <Paragraph>
            {elp.paragraph}
          </Paragraph>
        ))}
      <List>
        {
          data.items?.map((el) => (
            <ListItemWrapper key={el}>
              <ListItem>{el.item}</ListItem>
              {el.subItems
                && (
                <SubList>
                    {el.subItems?.map((subItem) => (<li>{subItem}</li>))}
                </SubList>
                )}
            </ListItemWrapper>
          ))
        }
      </List>

  </Wrapper>
);

export default ArticleItem;