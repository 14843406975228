import React from 'react';
import styled from 'styled-components';
import { HelmetProvider } from 'react-helmet-async';
import Head from 'Head/Head';
import Footer from 'components/organisms/Footer/Footer';
import ArticleItem from 'components/molecules/ArticleItem/ArticleItem';
import NNavigation from 'components/organisms/Navigation/NNavigation';
import Breadcrumbs from 'components/organisms/Breadcrumbs/Breadcrumbs';
import { useTranslation } from 'react-i18next';

const helmetContext = {};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 180px);
  margin: 0px auto 72px;
  max-width: 1920px;

  @media (max-width: 1024px) {
    width: calc(100% - 40px);
  }
`;

const PrivacyPolicyView = () => {
  const { t } = useTranslation(['policy']);
  const data = t('policy', { returnObjects: true });
  const bread = t('policy:breadcrumbs', { returnObjects: true });

  return (
  <HelmetProvider context={helmetContext}>
      <Head
        title="Polityka Prywatności Infinisap"
        description="Wybierz karierę z Infinisap"
      />
      <NNavigation />
      <Breadcrumbs data={bread} />
      <Wrapper>
          {
            data.map((item) => (
              <ArticleItem data={item} />
            ))
          }
      </Wrapper>
      <Footer />
  </HelmetProvider>
  );
};

export default PrivacyPolicyView;
