import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { HelmetProvider } from 'react-helmet-async';
import Head from 'Head/Head';
import Footer from 'components/organisms/Footer/Footer';
import JobArticle from 'components/molecules/JobArticle/JobArticle';
import JobForm from 'components/organisms/JobForm/JobForm';
import NNavigation from 'components/organisms/Navigation/NNavigation';
import NButtonPrimary from 'components/atoms/Buttons/NButtonPrimary';
import NButtonPrimaryExternal from 'components/atoms/Buttons/NButtonPrimaryExternal';
import HeroJobTemplate from 'components/organisms/HeroJobTemplate/HeroJobTemplate';
import NButtonBack from 'components/atoms/Buttons/NButtonBack';
import { useTranslation } from 'react-i18next';

const helmetContext = {};

const Wrapper = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  padding: 30px 0 72px;

  @media (max-width: 1024px) {
    padding: 0;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 96px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  column-gap: 20px;
  width: calc(100% - 160px);
  margin: 0px auto;
  max-width: 1920px;

  @media (max-width: 1024px) {
    display: flex;
    width: 100%;
    padding: 36px 20px 96px;
    row-gap: 24px;
  }
`;

const JobArticles = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 72px;
  grid-column: 3 / span 6;
  @media (max-width: 1024px) {
    padding-left: 0;
  }
`;

const JobCTA = styled.div`
  grid-row: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 28px;

  /* flex-direction: row;
  justify-content: end; */
  column-gap: 20px;

  grid-column: 3 / span 6;

  @media (max-width: 1024px) {
    align-items: normal;
    margin-top: 36px;
  }
`;

const NButtonBackWrapper = styled.div`
  justify-self: start;
  grid-row: 2;
  grid-column: 1 / span 2;
`;

const FadeIn1 = keyframes`
  from {
    transform: translate(-50%, -30%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
`;

const StyledJobForm = styled(JobForm)`
  z-index: 2;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  animation: 0.5s ${FadeIn1} ease-in-out;

  @media (max-width: 1024px) {
    width: 96%;
    top: 50%;
  }
`;

const FadeIn2 = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const JobFormBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 12, 51, 0.5);
  animation: 0.12s ${FadeIn2} ease-in-out;
`;

const JobTemaplte = ({ jobId, data, extlink }) => {
  const [isJobFormOpen, jobFormOpenFn] = useState(false);
  const title = data.filter((item) => item.id === Number(jobId)).map((item) => item.title);
  const location = data.filter((item) => item.id === Number(jobId)).map((item) => item.location);
  const elink = data.filter((item) => item.id === Number(jobId)).map((item) => item.externalLink);

  console.log(elink[0]);

  const { t } = useTranslation('jobOffersData');
  const Buttons = t('jobOffersData:buttons', { returnObjects: true });
  // const Link = t('jobOffersData:jobs', { returnObjects: true });

  if (isJobFormOpen) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'visible';
  }

  return (
    <>
      <HelmetProvider context={helmetContext}>
        <Head title={title} />
      </HelmetProvider>
      {isJobFormOpen && (
        <>
          <StyledJobForm title={title} jobFormOpenFn={jobFormOpenFn} />
          <JobFormBackground onClick={() => jobFormOpenFn(false)} />
        </>
      )}
      <NNavigation />
      <HeroJobTemplate title={title} location={location} />
      <Wrapper>
        <InnerWrapper>
          <JobArticles>
            {data
              .filter((item) => item.id === Number(jobId))
              .map((item) =>
                item.desc?.map((itemsEl) => (
                  <JobArticle key={itemsEl.heading} title={itemsEl.heading} items={itemsEl.items} />
                )),
              )}
          </JobArticles>
          <JobCTA>
            {extlink ? (
              <NButtonPrimaryExternal icon to={elink[0]} target="_blank" rel="noopener noreferrer">
                {Buttons[0].apply}&nbsp;
                <strong>Pracuj.pl</strong>
              </NButtonPrimaryExternal>
            ) : (
              <NButtonPrimary onClick={() => jobFormOpenFn(true)}>
                {Buttons[0].apply}
              </NButtonPrimary>
            )}
          </JobCTA>
          <NButtonBackWrapper>
            <NButtonBack icon to="/kariera/oferty-pracy">
              {Buttons[0].secondary}
            </NButtonBack>
          </NButtonBackWrapper>
        </InnerWrapper>
      </Wrapper>
      <Footer />
    </>
  );
};

export default JobTemaplte;
