import React from 'react';
import styled from 'styled-components';
import Image01 from 'assets/Services/konsultingsap.jpg';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import arrow from 'assets/Icons/arrow.svg';
import { useMediaQuery } from 'react-responsive';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  gap: 20px;
  min-height: 100px;
  width: calc(100% - 160px);
  margin: 0px auto 72px;
  max-width: 1920px;
  border-radius: 4px;
  margin-bottom: 121px;

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      padding: 24px 20px 8px;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
    }
`;

const ImageWrapper = styled.div`
  grid-column: 11 / -1;
  overflow: hidden;
  aspect-ratio: 3 / 4;
  border-radius: 4px;
  transform: translateX(-56px) translateY(36px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;

  @media (max-width: 1024px) {
    transform: unset;
    order: 0;
    aspect-ratio: 2 / 1;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const ContainerWrapper = styled.div`
  grid-column: 1 / span 10;
  display: flex;
  flex-direction: column;
  row-gap: 96px;
  border-radius: 4px;
  padding: 56px 76px 96px 56px;
  background: linear-gradient(135deg, #001331 0%, #9C8C8A 100%);

  @media (max-width: 1024px) {
    row-gap: 72px;
    padding: 36px 20px 72px 20px;
    order: 1;
  }
`;

const ContainerInnerWrapper = styled.div`
  display: flex;
  row-gap: 56px;
  flex-direction: column;

  @media (max-width: 1024px) {
    row-gap: 36px;
  }
`;

const Heading = styled.h1`
  font-size: 4.8rem;
  line-height: 120%;
  font-weight: 300;
  letter-spacing: -1.04px;
  color: #EAEBF0;

  @media (max-width: 1024px) {
      font-size: 3.2rem;
      letter-spacing: -1.08px;
  }
`;

const Heading2 = styled.h2`
  font-size: 2.4rem;
  line-height: 126%;
  font-weight: 400;
  letter-spacing: -0.24px;
  color: #EAEBF0;
  white-space: pre-line;

  @media (max-width: 1024px) {
      letter-spacing: -1.08px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;

const Paragraph = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 168%;
  color: #EAEBF0;
  column-count: 2;
  column-gap: 40px;
  max-width: 90%;
  white-space: pre-line;

  @media (max-width: 1024px) {
    column-count: unset;
    max-width: unset;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  max-width: 90%;

  @media (max-width: 1024px) {
    column-count: unset;
    max-width: unset;
  }
`;

const ListItem = styled(NavLink)`
  color: #EAEBF0;
  text-decoration: none;
  font-size: 1.6rem;
  letter-spacing: -0.19px;
  border-bottom: 1px solid rgba(255,255,255,.12);
  padding-bottom: 10px;
  font-weight: 500;
  text-transform: uppercase;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 1024px) {
    font-size: 1.3rem;
  }

  &:hover{
    opacity: 0.7;
  }

  &::after{
    content: '';
    width: 14px;
    height: 10px;
    mask-image: url(${arrow});
    mask-repeat: no-repeat;
    background-color: #EAEBF0;
  }
`;

const ServicesTemplateContentC = ({ data }) => {
  const modifySingleChars = (str) => str.replace(/ ([a-zA-Z]) /g, ' $1'.concat('\u00A0'));
  const isQueryMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  return (
  <Wrapper>
    <ContainerWrapper>
      <ContainerInnerWrapper>
        <Heading>{data.title}</Heading>
        <ContentWrapper>
        <Paragraph>
            {data.content.map((el) => modifySingleChars(el.paragraph))}
        </Paragraph>
        </ContentWrapper>
      </ContainerInnerWrapper>
      <ContainerInnerWrapper>
          {data.subcontent?.map((el) => (
            <>
              <Heading2>{el.heading}</Heading2>
              <List>
                {el.list.map((item) => (
                  <ListItem to={item.link}>{item.name}</ListItem>
                ))}
              </List>
            </>
          ))}
      </ContainerInnerWrapper>
    </ContainerWrapper>
    <ImageWrapper>
      <Image src={!isQueryMobile ? data.image : data.imageM} alt={data.title} />
    </ImageWrapper>
  </Wrapper>
  );
};

Image.propTypes = { src: PropTypes.string };
Image.defaultProps = { src: Image01 };

export default ServicesTemplateContentC;