import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
`;

const Image = styled.img`
    object-fit: contain;
    image-rendering: crisp-edges;
    border-radius: 3px;
    aspect-ratio: 1 / 1;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 0 10px -3px rgba(0,0,0,.24);

    ${({ border }) => border && css`
      border: 1px solid #008cd2;
    `}

    ${({ noShadow }) => noShadow && css`
      box-shadow: unset;
    `}
`;

const CertItem = ({
  src, alt, border, noShadow
}) => (
  <Wrapper>
    <Image
      src={src}
      alt={alt}
      border={border}
      noShadow={noShadow}
    />
  </Wrapper>
);

CertItem.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default CertItem;