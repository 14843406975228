import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Head from 'Head/Head';
import Footer from 'components/organisms/Footer/Footer';
import NewsTemplate from 'templates/NewsTemplate/NewsTemplate';
import AboutUsTemplate from 'templates/AboutUsTemplate/AboutUsTemplate';
import HeroHome from 'components/organisms/HeroHome/HeroHome';
import NNavigation from 'components/organisms/Navigation/NNavigation';
import Cert from 'components/organisms/Cert/Cert';

const helmetContext = {};

const HomeView = ({ darkModefn }) => {
  useEffect(() => {
    darkModefn(false);
  }, [darkModefn]);
  return (
    <>
      <HelmetProvider context={helmetContext}>
        <Head
          title="Infinisap, unendliche Möglichkeiten der SAP®-Technologie"
          description="Indem wir Wissen und Erfahrung mit frischen und agilen Ideen verbinden, führen wir Kundenprojekte zum Erfolg und gestalten gemeinsam die Zukunft der SAP®-Beratung."
        />
      </HelmetProvider>
      <NNavigation />
      <HeroHome />
      <NewsTemplate />
      <AboutUsTemplate />
      <Cert />
      <Footer />
    </>
  );
};

export default HomeView;
