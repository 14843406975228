import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
    padding-right: 80px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    color: rgba(255,255,255, 0.7);
    font-size: 1.3rem;
    line-height: 156%;
    padding-top: 36px;
    @media (max-width: 1024px) {
      row-gap: 24px;
      padding-right: 0;
      order: 1;
    }

    >p{
      @media (max-width: 1024px) {
        font-size: 1.1rem;
      }
    }

`;

const Links = styled.div`
    display: flex;
    column-gap: 24px;
`;

const StyledLink = styled(NavLink)`
    font-size: 1.3rem;
    color: rgba(255,255,255, 0.7);
    text-decoration: none;

    @media (max-width: 1024px) {
      font-size: 1.1rem;
    }

    display: flex;
    flex-direction: column;
    row-gap: 3px;
    overflow: hidden;
    transition: all 0.28s ease-in-out;

    &::after{
      content: '';
      width: 101%;
      height: 1px;
      border-bottom: 1px solid rgba(255,255,255, 0.7);
      transition: transform 0.28s ease-in-out;
      transform: translateX(0);
    }

    &:hover, &.active{
      cursor: pointer;
      color: rgba(255,255,255, 1);
      &::after{
        width: 100%;
      }
    }
`;

const Policy = () => {
  const { t } = useTranslation(['sitemap']);
  const policyLink = t('sitemap:policyLinks', { returnObjects: true });
  const copy = t('sitemap:copy', { returnObjects: true });

  return (
    <Wrapper>
        <Links>
            {policyLink.map((item) => (
                  <StyledLink to={item.link} small="true">{item.name}</StyledLink>
            ))}
        </Links>
        <p>{copy[0].text}</p>
    </Wrapper>
  );
};

export default Policy;