import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
`;

const NavItem = styled(NavLink)`
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    color: inherit;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0;
    text-decoration: none;
    overflow: hidden;
    position: relative;
    color: #595959;
    font-weight: 500;
    border-left: 1px solid #002afa;
    padding: 1px 0;
    padding-left: 12px;
    transition: all 0.28s ease-in-out;

    @media (max-width: 1024px) {
        font-size: 1.4rem;
        font-weight: 400;
        margin-left: 10px;
    }

    ${({ active }) => active && css`
        font-weight: 400;
        @media (max-width: 1024px) {
            font-weight: 600;
            opacity: 1;
        }
        padding-left: 14px;
        border-left: 1px solid #002afa;
        color: #002afa;
        &::before{
            transform: translateX(0%);
        }
    `}

    &:hover, &.active{
        padding-left: 14px;
        border-left: 1px solid #002afa;
        color: #002afa;
        &::before{
            transform: translateX(0%);
        }
    }
`;

const NavigationItemListSub = ({ children, ...props }) => (
    <Wrapper>
        <NavItem {...props}>{children}</NavItem>
    </Wrapper>
);

export default NavigationItemListSub;