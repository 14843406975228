import LinkedIn from 'components/atoms/Icons/LinkedIn';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 20px;
`;

const SocialIcons = styled.div`
  align-self: stretch;
  display: flex;
  column-gap: 14px;
`;

const InnerWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const Email = styled(NavLink)`
  font-size: 2.1rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
`;

const PhoneNumbers = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 11px;
`;

const PhoneItem = styled.a`
  font-size: 2.1rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
`;

const FooterCaption = styled(NavLink)`
  text-decoration: none;
  color: rgba(255, 255, 255, 1);

  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  text-transform: uppercase;
`;

const Address = () => (
  <Wrapper>
    <InnerWrapper>
      <FooterCaption to="/kontakt">Kontakt</FooterCaption>
      <Email to="mailto:office@infinisap.com">office@infinisap.com</Email>
      <PhoneNumbers>
        <PhoneItem>+48 668 830 563</PhoneItem>
        <PhoneItem>+49 173 3744 022</PhoneItem>
      </PhoneNumbers>
    </InnerWrapper>
    <SocialIcons>
      <LinkedIn />
    </SocialIcons>
  </Wrapper>
);

export default Address;
