import React, { useContext, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Head from 'Head/Head';
import Footer from 'components/organisms/Footer/Footer';
import NNavigation from 'components/organisms/Navigation/NNavigation';
import { ThemeContext } from 'context';
import { GlobalStyleDark } from 'theme/GlobalStyle';
import HeroMission from 'components/organisms/Mission/HeroMission/HeroMission';
import ContentMission from 'components/organisms/Mission/HeroMission/ContentMission';

const helmetContext = {};

const MissionView = ({ darkModefn }) => {
  const isDarkModeContext = useContext(ThemeContext);

  useEffect(() => {
    darkModefn(true);
  }, [darkModefn]);

  return (
    <>
    <GlobalStyleDark />
    <HelmetProvider context={helmetContext}>
      <Head
        title="Misja firmy Infinisap"
        description="2023"
      />
      <NNavigation dark={isDarkModeContext} />
      <HeroMission />
      <ContentMission />
      <Footer />
    </HelmetProvider>
    </>
  );
};
export default MissionView;
