import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Head from 'Head/Head';
import Footer from 'components/organisms/Footer/Footer';
import NNavigation from 'components/organisms/Navigation/NNavigation';
import ContactContent from 'components/organisms/Contact/ContactContent';

const helmetContext = {};

const ContactView = ({ darkModefn }) => {
  useEffect(() => {
    darkModefn(false);
  }, [darkModefn]);
  return (
    <>
      <HelmetProvider context={helmetContext}>
        <Head
          title="Infini... SAP® Zukunftsszenario!"
          description="Wenn es eine Sache gibt, die man über Infinisap sagen kann, dann ist es die, dass für uns nichts unmöglich ist!"
        />
      </HelmetProvider>
      <NNavigation />
      <ContactContent />
      <Footer />
    </>
  );
};

export default ContactView;
