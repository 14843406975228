import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;

  column-gap: 20px;
  width: calc(100% - 160px);
  margin: 12px auto 121px;
  max-width: 1920px;
  border-radius: 4px;
  overflow: hidden;
  row-gap: 20px;
  counter-reset: section;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;

    aspect-ratio: unset;
    padding: 0 20px 44px 20px;
    width: 100%;
  }
`;

const Item = styled.p`
  display: flex;
  flex-direction: column;
  padding: 36px;
  grid-column: 2 / span 7;
  font-size: 1.72rem;
  font-weight: 400;
  line-height: 148%;
  background-color: transparent;
  color: #070f28;
  border-bottom: 1px solid #070f28;

  display: flex;
  flex-direction: row;
  column-gap: 20px;
  white-space: pre-line;
  align-items: center;
  &::before{
    counter-increment: section;
    content: counter(section) ".";
    font-size: 3.6rem;
  }

  @media (max-width: 1024px) {
    font-size: 1.6rem;
    row-gap: 20px;
    padding: 20px;
    align-items: flex-start;
    flex-direction: column;
  }
`;

const RecruitmentContent = ({ data }) => {
  const modifySingleChars = (str) => str.replace(/ \b([a-zA-Z]{1,3})\b /g, ' $1'.concat('\u00A0'));

  return (
  <Wrapper>
    {data.map((item) => (<Item>{modifySingleChars(item)}</Item>))}
  </Wrapper>
  );
};

export default RecruitmentContent;