import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.address`
  font-style: normal;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  @media (max-width: 1024px) {
    row-gap: 10px;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: flex-start;
  border-bottom: 1px solid rgba(242, 250, 252, 0.18);
  padding-bottom: 22px;
  &:last-child {
    border-bottom: unset;
  }
  @media (max-width: 1024px) {
    padding-bottom: 24px;
    padding-top: 12px;
    row-gap: 8px;
    border-bottom: 1px solid rgba(242, 250, 252, 0.18);
    &:last-child {
      border-bottom: unset;
    }
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  @media (max-width: 1024px) {
    row-gap: 4px;
  }
`;

const Caption = styled.span`
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: rgba(242, 250, 252, 0.88);
  @media (max-width: 1024px) {
    font-size: 1.1rem;
    color: rgba(242, 250, 252, 0.8);
    letter-spacing: 0.12em;
    padding-bottom: 4px;
  }
`;

const Heading1 = styled.span`
  letter-spacing: -0.38px;
  font-size: 2.1rem;
  line-height: 144%;
  font-weight: 400;
  color: rgba(242, 250, 252, 1);

  @media (max-width: 1024px) {
    font-size: 1.6rem;
  }
`;

const Heading = styled.span`
  letter-spacing: -0.38px;
  font-size: 1.76rem;
  line-height: 144%;
  font-weight: 400;
  color: rgba(242, 250, 252, 1);
  white-space: pre-line;

  @media (max-width: 1024px) {
    font-size: 1.6rem;
  }
`;

const Text = styled.p`
  column-gap: 20px;
  color: rgba(242, 250, 252, 0.84);

  font-size: 1.5rem;
  font-weight: 400;
  line-height: 154%;

  ${({ small }) =>
    small &&
    css`
      font-size: 1.1rem;
      line-height: 144%;
    `}
  ${({ medium }) =>
    medium &&
    css`
      font-size: 1.24rem;
      line-height: 146%;
      opacity: 0.9;
      white-space: pre-line;
    `}
`;

const LinkTo = styled(NavLink)`
  letter-spacing: -0.38px;
  font-size: 2.1rem;
  line-height: 118%;
  font-weight: 400;
  color: #00ccff;
  text-decoration: none;

  display: flex;
  flex-direction: column;
  row-gap: 3px;

  @media (max-width: 1024px) {
    font-size: 1.6rem;
  }

  overflow: hidden;

  &::after {
    content: '';
    height: 1.4px;
    width: 100%;
    background-color: transparent;
    transform: translateX(-100%);
    transition: transform 0.21s ease-in-out;
  }

  &:hover {
    &::after {
      transform: translateX(0);
      background-color: #00ccff;
    }
  }
`;

const Phones = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
  @media (max-width: 1024px) {
    padding: 14px 0;
    row-gap: 10px;
  }
`;

const PhoneNumber = styled(NavLink)`
  letter-spacing: -0.38px;
  font-size: 2.1rem;
  line-height: 118%;
  font-weight: 400;
  color: rgba(242, 250, 252, 0.98);
  text-decoration: none;
  padding-top: 5px;
  padding-bottom: 5px;
  /* border-bottom: 1px solid rgba(242, 250, 252, 0.18); */

  display: flex;
  flex-direction: column;
  /* grid-template-columns: 40px 1fr; */

  @media (max-width: 1024px) {
    font-size: 1.6rem;
  }
`;

const Direc = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: rgba(242, 250, 252, 1);
  color: #00ccff;
  @media (max-width: 1024px) {
    font-size: 1.1rem;
    color: rgba(242, 250, 252, 0.8);
    letter-spacing: 0.12em;
    padding-bottom: 4px;
  }
`;

const NavTo = styled(NavLink)`
  text-decoration: none;
  color: #77ceff;
`;

const ContactAddresse = () => {
  const modifySingleChars = (str) => str.replace(/ \b([a-zA-Z]{1,3})\b /g, ' $1'.concat('\u00A0'));
  const { t } = useTranslation(['contact']);

  return (
    <Wrapper>
      <Item>
        <Header>
          <Caption>{t('content.caption')}</Caption>
          <Heading1>Infinisap Sp. z o.o.</Heading1>
        </Header>
        <Text>
          ul. Stalowa 4,
          <br />
          47-400 Racibórz
        </Text>
        <Text medium>{t('content.heading3')}</Text>
      </Item>

      <Item>
        <Header>
          {/* <Caption>Dane kontaktowe</Caption> */}
          <Heading>{t('content.heading1')}</Heading>
        </Header>
        <LinkTo to="mailto:office@infinisap.com">office@infinisap.com</LinkTo>
      </Item>

      <Item>
        <Header>
          <Heading>{t('content.heading2')}</Heading>
        </Header>
        <Phones>
          <PhoneNumber to="tel:0048668830563">
            <Direc>Pl</Direc>
            +48 668 830 563
          </PhoneNumber>
          <PhoneNumber to="tel:00491733744022">
            <Direc>De</Direc>
            +49 173 3744 022
          </PhoneNumber>
        </Phones>
      </Item>

      <Item>
        <Text small>
          {modifySingleChars(t('policy.text'))} <NavTo to={t('link.link')}>{t('link.text')}</NavTo>
        </Text>
      </Item>
    </Wrapper>
  );
};

export default ContactAddresse;
