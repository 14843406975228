import styled, { css } from 'styled-components';

const Text = styled.p`
    --palette: ${({ theme }) => theme.white};
    color: var(--palette);
    font-size: 1.6rem;
    line-height: 143%;
    font-weight: 400;

    @media (max-width: 1024px) {
      font-size: 1.4rem;
    }

    ${({ palette }) => (palette && css`
    --palette: ${palette};
    `

  )};

    ${({ small }) => (small ? css`
      font-size: 1.4rem;
      line-height: 148%;
      @media (max-width: 1024px) {
        font-size: 1.2rem;
      }
    ` : undefined)}

    ${({ smallX }) => (smallX ? css`
      font-size: 1.5rem;
      line-height: 148%;
      @media (max-width: 1024px) {
        font-size: 1.24rem;
      }
    ` : undefined)}

    ${({ verysmall }) => (verysmall ? css`
      font-size: 1.2rem;
      line-height: 132%;
      @media (max-width: 1024px) {
        font-size: 1rem;
        line-height: 125%;
      }
    ` : undefined)}

    ${({ large }) => (large ? css`
      font-size: 1.9rem;
      line-height: 143%;
      @media (max-width: 1024px) {
        font-size: 1.6rem;
        br{
          display: none;
        }
      }
    ` : undefined)}

    ${({ aCenter }) => aCenter && css`
      text-align: center;
    `}
`;

export default Text;
