import React from 'react';
import styled from 'styled-components';
import Sitemap from './Sitemap';
import Address from './Address';
import Policy from './Policy';
import Capital from './Capital';

const Wrapper = styled.div`
  width: 100%;
  /* background-color: #181a22; */
  background-color: #101219;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 72px 0 196px;
  row-gap: 56px;
  width: calc(100% - 160px);
  margin: 0px auto;
  max-width: 1920px;

  @media (max-width: 1024px) {
    padding: 56px 20px 144px;
    width: 100%;
  }

  > div {
    display: grid;
    grid-template-columns: auto minmax(auto, 500px);
    column-gap: 56px;
    &:last-child {
      align-items: start;
    }

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      row-gap: 48px;
      &:last-child {
        align-items: stretch;
      }
    }
  }
`;

const Footer = () => (
  <Wrapper>
    <InnerWrapper>
      <div>
        <Sitemap />
        <Address />
      </div>
      <div>
        <Policy />
        <Capital />
      </div>
    </InnerWrapper>
  </Wrapper>
);

export default Footer;
