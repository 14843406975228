import styled from 'styled-components';
import MissionDecor from 'assets/Images/MissionDecor.svg';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 20px;
  width: 100vw;
  padding: 36px 0;

  width: calc(100% - 160px);
  margin: 0px auto;
  max-width: 1920px;

  margin-bottom: 72px;
  border-radius: 4px;

  padding-bottom: 144px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    aspect-ratio: unset;
    padding: 0 20px 44px 20px;
    width: 100%;
  }
`;

const InnerWrapper = styled.div`
    grid-column: 4 / span 6;
    display: flex;
    flex-direction: column;
    row-gap: 44px;
`;

const Heading = styled.h1`
    font-size: 3.6rem;
    font-size: 3.0rem;
    line-height: 144%;
    letter-spacing: -0.38px;
    font-weight: 400;
    color: rgba(242, 250, 252, 0.88);

    @media (max-width: 1024px) {
        font-size: 3.2rem;
        line-height: 128%;
        letter-spacing: -0.34px;
        font-weight: 400;
    }
`;

const ImageIcon = styled.div`
    width: 100%;
    height: 100%;
    background-color: aquamarine;
    grid-column: 2 / span 2;
    background: url(${MissionDecor});
    background-repeat: no-repeat;
    background-position: top center;
    transform: translateY(-110px) rotate(20deg);
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-bottom: 16px;
    @media (max-width: 1024px) {
        margin-top: 4px;
        margin-bottom: 0px;
    }
`;

const Paragraph = styled.p`
    column-count: 2;
    column-gap: 40px;
    color: rgba(7, 11, 17, 0.9);

    font-size: 1.6rem;
    font-weight: 400;
    line-height: 168%;
    color: rgba(242, 250, 252, 0.88);
    white-space: break-spaces;

    @media (max-width: 1024px) {
        column-count: 1;
        br{
           display: block;
        }
    }
`;

const ContentMission = () => {
  const { t } = useTranslation(['mission']);
  const modifySingleChars = (str) => str.replace(/ \b([a-zA-Z]{1,3})\b /g, ' $1'.concat('\u00A0'));

  return (
    <Wrapper>
        <ImageIcon />
        <InnerWrapper>
            <HeaderWrapper>
                <Heading>{modifySingleChars(t('content.heading'))}</Heading>
            </HeaderWrapper>
            <Paragraph>{modifySingleChars(t('content.text'))}</Paragraph>
        </InnerWrapper>
    </Wrapper>
  );
};

export default ContentMission;