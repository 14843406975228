import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Head from 'Head/Head';
import Footer from 'components/organisms/Footer/Footer';
import NNavigation from 'components/organisms/Navigation/NNavigation';
import RecruitmentHero from 'components/organisms/Recruitment/RecruitmentHero';
import Breadcrumbs from 'components/organisms/Breadcrumbs/Breadcrumbs';
import RecruitmentContent from 'components/organisms/Recruitment/RecruitmentContent';
import { useTranslation } from 'react-i18next';

const helmetContext = {};

const CareerView = () => {
  const { t } = useTranslation('recruitmentProcess');
  const BreadcrumbsElements = t('recruitmentProcess:breadcrumbs', { returnObjects: true });
  const data = t('recruitmentProcess:hero', { returnObjects: true });
  const content = t('recruitmentProcess:items', { returnObjects: true });

  return (
    <HelmetProvider context={helmetContext}>
      <Head
        title="Infinisap Kariera"
        description="Wybierz karierę z Infinisap 2023"
      />
      <NNavigation />
      <Breadcrumbs data={BreadcrumbsElements} />
      <RecruitmentHero data={data} />
      <RecruitmentContent data={content} />
      <Footer />
    </HelmetProvider>
  );
};

export default CareerView;
