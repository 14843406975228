import { useContext } from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import arrowNavItem from 'assets/Icons/arrowNavItem.svg';
import { ThemeContext } from 'context';
import NavigationItemList from './NavigationItemList';

const StyledNavigationItemList = styled(NavigationItemList)`
  display: none;
  top: 48px;
  left: -36px;

  ${({ tlr }) => tlr && css`
    left: auto;
    right: -36px;
  `}

  @media (max-width: 1024px) {
    display: flex;
    margin-top: 20px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 36px;
  position: relative;

  @media (max-width: 1024px) {
    row-gap: 0;
  }

  &:hover{
    ${StyledNavigationItemList}{
        display: flex;
    }
  }
`;

const Item = styled(NavLink)`
  color: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
  text-decoration: none;
  overflow: hidden;
  row-gap: 6px;
  padding-bottom: 20px;

  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, auto);

  @media (max-width: 1024px) {
    padding-bottom: 0px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }

  &::after{
      content: '';
      width: 101%;
      height: 1px;
      transform: translateX(-101%);
      transition: transform 0.28s ease-in-out;
      border-bottom: 2px solid #002afa;
      grid-column: 1;
    }

    &:hover, &.active{
      opacity: 1;
      cursor: pointer;
      &::after{
        transform: translateX(0);
        width: 100%;
      }
    }

    ${({ data }) => data && css`
    grid-column-gap: 8px;
        &::before{
            mask-image: url(${arrowNavItem});
            mask-repeat: no-repeat;
            background: #0C141D;
            opacity: .7;
            content: '';
            grid-column: 2;
            grid-row: 1;
            align-self: center;
            width: 11px;
            height: 7px;
            @media (max-width: 1024px) {
              display: none;
            }
        }

        ${({ dark }) => dark && css`
          &::before{
              background: rgba(242, 250, 252, 0.94);
            }
        `}

        ${({ isTint }) => isTint && css`
            ${({ dark }) => dark && css`
            &::before{
              background: #0C141D;
            }
            `}
          `}
    `}
`;

const NavItem = ({
  children, isTintFn, isTint, data, tlr, isMobileNavOpenFn, ...props
}) => {
  const isDarkModeContext = useContext(ThemeContext);
  return (
    data ? (
    <Wrapper
      isTint={isTint}
    >
        <Item {...props} data={data} dark={isDarkModeContext} isTint={isTint}>{children}</Item>
        <StyledNavigationItemList data={data} tlr={tlr} isMobileNavOpenFn={isMobileNavOpenFn} />
    </Wrapper>
    ) : (
    <Item {...props}>{children}</Item>
    )
  );
};

export default NavItem;