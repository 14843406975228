import React from 'react';
import styled from 'styled-components';
import NButtonSecondary from 'components/atoms/Buttons/NButtonSecondary';
import HeroImage from 'assets/Recruitment/HeroImage.svg';
// import { useTranslation, withTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: auto;

  column-gap: 20px;
  width: 100vw;
  aspect-ratio: 2.71 / 1;
  gap: 56px;

  width: calc(100% - 160px);
  margin: 0px auto;
  max-width: 1920px;
  border-radius: 4px;
  overflow: hidden;

  position: relative;
  background-color: #282c3d;

  @media (max-width: 1024px) {
    row-gap: 24px;
    display: flex;
    flex-direction: column;
    padding: 44px 20px;
    grid-template-columns: repeat(2, 1fr);
    width: calc(100% - 40px);
    aspect-ratio: unset;
    margin-top: 24px;
  }
`;

const Content = styled.div`
  grid-column: 2 / span 8;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 36px;

  @media (max-width: 1024px) {
    font-size: 2.4rem;
    letter-spacing: -1.08px;
    line-height: 138%;
    font-weight: 400;
    order: 1;
    row-gap: 24px;
  }
`;

const Heading = styled.h1`
  font-size: 6.9rem;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: -1.04px;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  column-gap: 20px;

  font-size: 5.6rem;
  font-weight: 300;
  color: #a9f7ff;
  color: #eff9ff;
  line-height: 120%;
  letter-spacing: -1.04px;
  white-space: pre-line;

  @media (max-width: 1024px) {
    font-size: 2.8rem;
    letter-spacing: -1.08px;

    font-size: 2.4rem;
    letter-spacing: -1.08px;
    line-height: 138%;
    font-weight: 400;
  }
`;

const Description = styled.p`
  font-size: 1.76rem;
  /* font-size: 1.6rem; */
  font-weight: 400;
  line-height: 158%;
  color: #eff9ff;
  white-space: pre-line;

  @media (max-width: 1024px) {
    font-size: 1.4rem;
    letter-spacing: 0;

    br {
      display: none;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  column-gap: 44px;
  align-items: center;
  margin-top: 36px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 8px;
    margin-top: 0px;
  }
`;

const ImageWrapper = styled.div`
  background-color: beige;
  grid-column: 7 / span 3;
  mask-image: url(${HeroImage});
  mask-repeat: no-repeat;
  mask-position: center center;
  background-color: #5ec6ff;
  mask-size: 100%;
  transform: rotate(-7deg);

  position: absolute;
  bottom: -20px;
  left: 20px;
  width: 504px;
  height: 100%;
  transform: rotate(-2deg);

  @media (max-width: 1024px) {
    order: 0;
    width: 100%;
    height: 180px;
    position: unset;
    mask-size: contain;
    mask-position: left center;
  }
`;

const CareerHero = ({ data, buttons }) => {
  console.log(data);
  return (
    <Wrapper>
      <Content>
        <Heading>{data[0].heading}</Heading>
        <Description>{data[0].description}</Description>
        <Buttons>
          <NButtonSecondary colorParent="#5ec6ff" to="/kariera/oferty-pracy">
            {buttons[0].oferty}
          </NButtonSecondary>
          <NButtonSecondary colorParent="#5ec6ff" to="/kariera/proces-rekrutacji">
            {buttons[0].proces}
          </NButtonSecondary>
          <NButtonSecondary colorParent="#5ec6ff" to="/kariera/praktyki">
            {buttons[0].praktyki}
          </NButtonSecondary>
        </Buttons>
      </Content>
      <ImageWrapper />
    </Wrapper>
  );
};

export default CareerHero;
