import styled from 'styled-components';
import Image001 from 'assets/Images/Image001.jpg';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    row-gap: 40px;

    @media (max-width: 1024px) {
        row-gap: 36px;
    }
`;

const Image = styled.img`
    aspect-ratio: 16 / 9;
    background: url(${Image001});
    object-fit: cover;
    border-radius: 4px;
    @media (max-width: 1024px) {
        aspect-ratio: 1 / 1;
    }
`;

const Caption = styled.span`
    font-weight: 500;
    color: rgba(12, 20, 29, 0.7);

    font-size: 1.3rem;
    font-weight: 500;
    color: rgba(12, 20, 29, 0.7);
    letter-spacing: 0.04em;
    text-transform: uppercase;
`;

const Heading = styled.h1`
    font-size: 3.6rem;
    line-height: 122%;
    letter-spacing: -0.68px;
    font-weight: 400;
    color: #070B11;

    @media (max-width: 1024px) {
        font-size: 3.2rem;
        line-height: 128%;
        letter-spacing: -0.34px;
        font-weight: 400;
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-bottom: 16px;
    @media (max-width: 1024px) {
        margin-top: 4px;
        margin-bottom: 0px;
    }
`;

const Paragraph = styled.p`
    column-count: 2;
    column-gap: 40px;
    color: rgba(7, 11, 17, 0.9);

    font-size: 1.5rem;
    font-weight: 400;
    line-height: 162%;
    color: #0C141D;
    color: rgba(12, 20, 29, 0.9);
    white-space: pre-line;

    br{
        display: none;
    }

    @media (max-width: 1024px) {
        column-count: 1;
        br{
           display: block;
        }
    }
`;

const ArticlePrimary = ({ className }) => {
  const { t } = useTranslation(['certification']);

  return (
    <Wrapper className={className}>
        <Image src={Image001} />
        <HeaderWrapper>
            <Caption>{t('caption')}</Caption>
            <Heading>{t('heading')}</Heading>
        </HeaderWrapper>
        <Paragraph>{t('textContent')}</Paragraph>
    </Wrapper>
  );
};

export default ArticlePrimary;