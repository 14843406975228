import React from 'react';
import styled from 'styled-components';
// import LogoIconImg from 'assets/Contact/LogoIcon.svg';
import { useTranslation } from 'react-i18next';
import ContactAddresse from './ContactAddresse';
import SpaceImage from '../../../assets/Contact/Space.svg';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 0;
  width: calc(100% - 160px);
  gap: 56px;
  max-width: 1920px;
  margin: 44px auto 144px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    aspect-ratio: unset;
    padding: 0 20px 44px 20px;
    width: 100%;
    gap: 24px;
  }

`;

const Item1 = styled.div`
  grid-column: 2 / span 6;
  /* border-left: 1px solid #182B55; */

  display: flex;
  align-items: flex-start;
  padding: 0;

  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1024px) {
    padding: 0;
    padding-left: 24px;
    padding-top: 24px;
    align-items: flex-start;
    border-left: unset;
    border-top: 1px solid #182B55;
  }
`;

const Item2 = styled.div`
  grid-column: 8 / span 6;
  aspect-ratio: 2 / 1;
  width: 100%;
  border-radius: 4px;
  background-color: #131C4E;
  background-color: #022448;
  padding: 56px 56px 36px;

  @media (max-width: 1024px) {
    /* transform: translateY(-120px); */
    padding: 24px;
    /* padding-bottom: 72px; */
  }
`;

const Heading = styled.h1`
  font-size: 4.8rem;
  font-weight: 300;
  color: #0B121B;
  line-height: 120%;
  letter-spacing: -1.04px;
  padding-top: 56px;
  white-space: pre-line;

  @media (max-width: 1024px) {
      font-size: 3.0rem;
      letter-spacing: -1.08px;
      text-align: left;
      padding-top: 0px;
      /* white-space: nowrap; */
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  transform: translateY(-20px) translateX(60px) rotate(-10deg);
  @media (max-width: 1024px) {
    /* height: 300px; */
    /* transform: translateY(-60px) translateX(20px) rotate(-10deg); */
  }
`;

const Image = styled.div`
  mask-image: url(${SpaceImage});
  mask-repeat: no-repeat;
  width: 100%;
  height: 100%;
  mask-size: 74%;
  mask-position: top center;
  background-color: black;
`;

const ContactHero = () => {
  const { t } = useTranslation(['contact']);

  return (
  <Wrapper>
    <Item1>
      <Heading>{t('hero.heading')}</Heading>
      <ImageWrapper>
        <Image />
      </ImageWrapper>
    </Item1>
    <Item2>
      <ContactAddresse />
    </Item2>
    {/* {Array(2).fill().map((el, i) => <Item3Lines key={{ i }} />)} */}
  </Wrapper>
  );
};

export default ContactHero;