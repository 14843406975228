import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 14px;
    color: rgba(255,255,255, 0.58);
    font-size: 1.2rem;
    line-height: 156%;

    padding-top: 36px;
    border-top: 1px solid rgba(255,255,255,0.28);
    br{
        display: none;
    }
    @media (max-width: 1024px) {
        br{
        display: block;
        }
    }
`;

const Capital = () => {
  const { t } = useTranslation(['sitemap']);
  const imprint = t('sitemap:imprint', { returnObjects: true });

  return (
    <Wrapper>
        <p>{imprint[0].address}</p>
        <p>{imprint[0].budget}</p>
    </Wrapper>
  );
};

export default Capital;