import styled from 'styled-components';
import ArticlePrimary from 'components/organisms/Articles/ArticlePrimary';

const Wrapper = styled.section`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: 144px 80px 144px 80px;
    padding: 36px 80px;

    max-width: 1920px;
    margin: 0 auto;

    @media (max-width: 1024px) {
        padding: 72px 20px;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
    }
`;

const StyledArticlePrimary = styled(ArticlePrimary)`
    grid-column: 4 / span 6;
    @media (max-width: 1024px) {
        grid-column: 1 / span 2;
    }
`;

const AboutUsTemplate = () => (
    <Wrapper>
        <StyledArticlePrimary />
    </Wrapper>
);

export default AboutUsTemplate;