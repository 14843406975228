import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Head from 'Head/Head';
import Footer from 'components/organisms/Footer/Footer';
import NNavigation from 'components/organisms/Navigation/NNavigation';

const helmetContext = {};

const ContactView = ({ darkModefn }) => {
  useEffect(() => {
    darkModefn(false);
  }, [darkModefn]);
  return (
  <HelmetProvider context={helmetContext}>
      <Head
        title="Infinisap"
        description="2023"
      />
      <NNavigation />
      <Footer />
  </HelmetProvider>
  );
};

export default ContactView;
