import Button from 'components/atoms/Buttons/Button';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 20px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.greyAccent};
  padding: 24px;
  /* border-radius: 4px; */
  border-radius: 14px;
  padding-bottom: 48px;
  @media (max-width: 1024px) {
    padding: 20px 12px;
  }

 ${Button}{
  margin-top: 18px;
  @media (max-width: 1024px) {
    margin-top: 4px;
  }
 }
`;

const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const Caption = styled.span`
    font-size: 1.2rem;
    font-weight: 500;
    color: rgba(12, 20, 29, 0.7);
    letter-spacing: 0.07em;
    text-transform: uppercase;
    padding: 0 8px;
`;

const Heading = styled.h2`
    letter-spacing: -0.38px;
    font-size: 2.2rem;
    line-height: 118%;
    font-weight: 500;
    color: #070B11;
    padding: 0 8px;
`;

const Paragraph = styled.p`
    column-gap: 20px;
    color: rgba(12, 20, 29, 0.8);
    padding: 0 8px;

    font-size: 1.5rem;
    font-weight: 400;
    line-height: 154%;
`;

const JobItem = ({
  className,
  date,
  title,
  location,
  path,
}) => {
  const { t } = useTranslation('jobOffersData');
  const Buttons = t('jobOffersData:buttons', { returnObjects: true });

  return (
  <Wrapper className={className}>
    <HeaderWrapper>
      <Caption>{date}</Caption>
      <Heading>{title}</Heading>
    </HeaderWrapper>
    <Paragraph palette={({ theme }) => theme.blue900}>{location}</Paragraph>
    <Button to={path}>{Buttons[0].primary}</Button>
  </Wrapper>
  );
};

export default JobItem;