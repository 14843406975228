import { useContext } from 'react';
import styled, { css } from 'styled-components';
import { ThemeContext } from 'context';

const Wrapper = styled.button`
  width: 54px;
  height: 42px;
  padding: 4px 10px;
  flex-direction: column;
  justify-content: center;
  row-gap: 6px;

  background-color: transparent;
  border: unset;
  cursor: pointer;

  z-index: 1;
  @media (max-width: 1024px) {
    display: flex;
    position: fixed;
    right: 20px;
  }


  &::before,
  &::after{
    content: '';
    background-color: #202329;
    height: 2px;
    width: 100%;
    transition: all 0.28s cubic-bezier(0.7, 0, 0.29, 1);

    ${({ dark }) => dark && css`
      height: 1.6px;
      background-color: rgba(242, 250, 252, 0.84);
    `}
  }


  @media (min-width: 1024) {
    &:hover{
      &::before,
      &::after{
        content: '';
        background-color: #1D47D4;
      }
      &::before{
        transform: translateX(5px);
      }
      &::after{
        transform: translateX(-5px);
      }
    }
  }


  ${({ active }) => active && css`
    &::before,
    &::after{
      content: '';
      background-color: #1D47D4;
    }
    &::before{
      transform: translateX(5px);
    }
    &::after{
      transform: translateX(-5px);
    }
  `}
`;

const NHamburger = ({ active, ...props }) => {
  const isDarkModeContext = useContext(ThemeContext);
  return (
  <Wrapper dark={isDarkModeContext} active={active} {...props} />
  );
};

export default NHamburger;