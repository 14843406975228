import React from 'react';
import styled from 'styled-components';
import JobItem from 'components/molecules/JobItem/JobItem';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 0 56px;

  margin-bottom: 72px;
  @media (max-width: 1024px) {
    padding: 0;
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  max-width: 1920px;
  margin: 0 auto;
  width: calc(100% - 160px);

  @media (max-width: 1024px) {
    padding: 0;

    width: calc(100% - 40px);
    aspect-ratio: unset;
    margin-top: 24px;
  }
`;

const JobsList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row;
  row-gap: 36px;
  margin: 0;
  padding: 0;
  gap: 20px;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;

const Jobs = ({ dataShows, data }) => (
  <Wrapper>
    <InnerWrapper>
      <JobsList>
        {
          data.slice(0, dataShows).map((job) => (
            <JobItem
              key={job.id}
              date={job.date}
              title={job.title}
              location={job.location}
              path={job.path}
            />
          ))
        }
      </JobsList>
    </InnerWrapper>
  </Wrapper>
);
export default Jobs;